import React from "react";

import Avatar from "@material-ui/core/Avatar";
import getPlatformLayout from "./getPlatformLayout";

import SearchIcon from "@material-ui/icons/Search";
import PracticeIcon from "@material-ui/icons/ListAlt";
import MobileAppIcon from "@material-ui/icons/GetApp";
import PerkIcon from "@material-ui/icons/CardGiftcard";
import LightIcon from "@material-ui/icons/Brightness5";
import GiftIcon from "@material-ui/icons/CardGiftcard";
import BlogsIcon from "@material-ui/icons/LibraryBooks";
import MockTestIcon from "@material-ui/icons/LaptopMac";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InsertChartIcon from "@material-ui/icons/InsertChart";

import { ROUTE_PRACTICE, STEPS_DID_ONBOARDING_PRACTICE } from "./types";

// Onboarding steps cutout position
const getOnBoardingCutouts = (step) => {
  if (step === 1) {
    return {
      top: "63px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "You can access your dashboard with this icon.",
      icon: <DashboardIcon />,
    };
  } else if (step === 2) {
    return {
      top: "113px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "You can practice individual exercises in this section.",
      icon: <PracticeIcon />,
    };
  } else if (step === 3) {
    return {
      top: "163px",
      left: "0px",
      width: "65px",
      height: "65px",
      description:
        "Here is the mock test section with your results and scorecards.",
      icon: <MockTestIcon />,
    };
  } else if (step === 4) {
    return {
      top: "213px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Check your progress and study plan in this section.",
      icon: <InsertChartIcon />,
    };
  } else if (step === 5) {
    return {
      top: "263px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Find tips and articles from PTE teachers and experts.",
      icon: <BlogsIcon />,
    };
  } else if (step === 6) {
    return {
      top: "313px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Access exclusive offers and perks for students.",
      icon: <PerkIcon />,
    };
  } else if (step === 7) {
    return {
      top: "363px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "No sure how PTE Academic works? Use this icon.",
      icon: <SearchIcon />,
    };
  } else if (step === 8) {
    return {
      top: "413px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Download the PTE Success mobile app.",
      icon: <MobileAppIcon />,
    };
  } else if (step === 9) {
    return {
      top: "0px",
      right: "0px",
      width: "65px",
      height: "65px",
      description: "Your profile and options are right there.",
      icon: (
        <Avatar
          alt="pte login"
          src={
            "https://pineapplestudio.com.au/pte_success_v2/images/avatars/avatar_default.svg"
          }
        />
      ),
    };
  } else if (step === 10) {
    return {
      top: "0px",
      right: "7rem",
      width: "65px",
      height: "65px",
      description: "Change between light and night mode.",
      icon: <LightIcon style={{ color: "#FFDA22" }} />,
    };
  } else if (step === 11) {
    return {
      top: "0px",
      left: "0px",
      width: "63px",
      height: "63px",
      description: "Click the logo at anytime to go back to the Dashboard.",
      icon: (
        <img
          alt="pte success - online practice free"
          style={{ width: "49px", height: "49px" }}
          src={getPlatformLayout("logo")}
        />
      ),
    };
  } else if (step === 12) {
    return {
      bottom: "0px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Get rewards by inviting your friends!",
      icon: <GiftIcon style={{ color: "#FFDA22" }} />,
    };
  } else {
    return {
      top: "0px",
      left: "0px",
      width: "65px",
      height: "65px",
      description: "Practice individual exercises here.",
      icon: <DashboardIcon />,
    };
  }
};

// Onboarding with large images
const getOnBoardingLarge = (pathname) => {
  if (pathname === ROUTE_PRACTICE) {
    return {
      steps: [
        "Every sections",
        "Select an exercise",
        "Real practice questions",
        "Prediction questions",
        "Your favorites",
        "Organise and jump questions",
      ],
      title: "Get the most from your practice",
      descriptions: [
        "Select which section to practice.",
        "Choose an exercise in the list.",
        "See how many times this question appeared in the question bank.",
        "Display the questions most likely to appear now in the PTE exam.",
        "Click this icon to display your saved favorite questions.",
        "Navigate through the list of questions.",
      ],
      images: [
        "onboarding_practice_1",
        "onboarding_practice_2",
        "onboarding_practice_3",
        "onboarding_practice_4",
        "onboarding_practice_5",
        "onboarding_practice_6",
      ],
      onboardingKey: STEPS_DID_ONBOARDING_PRACTICE,
    };
  }
};

export { getOnBoardingCutouts, getOnBoardingLarge };
