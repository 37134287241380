import React from "react";

import MicIcon from "@material-ui/icons/Mic";
import WriteIcon from "@material-ui/icons/Create";
import FireIcon from "@material-ui/icons/Whatshot";
import HeadsetIcon from "@material-ui/icons/Headset";
import BookIcon from "@material-ui/icons/LibraryBooks";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import { getExerciseSettings } from "./getExerciseData";
import { getMaxTaskScore } from "./getEnablingSkillData";
import { READING, WRITING, SPEAKING, LISTENING } from "./types";

// Sort data by property
const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

// Sort ascending or descending
const getSorting = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};

// Sort rows data
const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

// Get score if question done by user
const getScore = (uid, user) => {
  const scores = user.scores;
  if (!scores || !scores.scoreArray) return "N/A";

  const scoreEntry = scores.scoreArray.find((score) => score.uid === uid);
  if (!scoreEntry) return "N/A";

  const [currentScore, currentMaxTaskScore] = scoreEntry.score
    .split("/")
    .map(Number);
  const newMaxTaskScore = parseInt(getMaxTaskScore(uid), 10);

  const newScore =
    [SPEAKING, WRITING].includes(getExerciseSettings(uid).section) &&
    currentMaxTaskScore !== newMaxTaskScore
      ? `${Math.round(
          (currentScore * newMaxTaskScore) / currentMaxTaskScore
        )}/${newMaxTaskScore}`
      : scoreEntry.score;

  return newScore;
};

// Return exercise preview from string or array
const getPreview = (preview, width) => {
  const max = width === "sm" ? 20 : 100; // For mobile

  return typeof preview === "string"
    ? `${preview.substring(0, max)}${preview.length > max ? "..." : ""}`
    : preview && preview.length > 0
    ? `${preview[0].substring(0, max)}${preview[0].length > max ? "..." : ""}`
    : "";
};

// Return icon for section tabs
const getIcon = (label) => {
  switch (label) {
    case READING:
      return <BookIcon />;
    case LISTENING:
      return <HeadsetIcon />;
    case SPEAKING:
      return <MicIcon />;
    case WRITING:
      return <WriteIcon />;
    case "Favorite":
      return <FavoriteIcon />;
    case "Unanswered":
      return <AssignmentTurnedInIcon />;
    default:
      return <FireIcon />;
  }
};

export { getIcon, stableSort, getSorting, getScore, getPreview };
