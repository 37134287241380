import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Filter from "bad-words";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import AudioPlayer from "./components/AudioPlayer";
import WarningMobile from "./components/WarningMobile";
import RecorderManager from "./components/RecorderManager";
import AlertSkillAnalysis from "../alerts/AlertSkillAnalysis";

import isEmpty from "../../helpers/isEmpty";
import { isDesktop } from "../../helpers/getBrowserData";
import { isAuthenticated } from "../../helpers/userCheck";

import { setPracticeScore } from "../../actions/questionActions";

const filter = new Filter();

// Speaking Respond Situation
function ExerciseSpeakingSituation({ question, didSubmit, ...props }) {
  const classes = useStyles();

  const { score } = props.analysis;
  const { speechText, forceShowAnswer } = props.recorder;
  const { correctAnswer, transcript, track, keywords, uid } = question;

  const [openedAlert, setOpenedAlert] = useState(false);

  const speechTextArray = !isEmpty(speechText[0])
    ? filter.clean(speechText[0]).split(" ")
    : [];

  // Save score
  useEffect(() => {
    if (score && !isEmpty(speechText)) {
      saveScore();
    }
  }, [score, speechText]);

  // Save score on submit
  function saveScore() {
    const finalScore = `${score.Final}/13`;
    props.setPracticeScore({
      uid,
      score: finalScore,
      successful: score.Final > 6,
      isAuthenticated: isAuthenticated(props.user),
    });
  }

  return (
    <Grid container direction="row" className={classes.screen}>
      {!isDesktop() && <WarningMobile />}
      <div className={classes.taskMargin}>
        <TaskBox uid={uid} />
      </div>
      <Grid item xs={12} className={classes.column}>
        <Typography
          style={{
            fontSize: "1.2rem",
            lineHeight: "1.7rem",
            paddingTop: "0.25rem",
            whiteSpace: "pre-line",
          }}
        >
          {transcript}
        </Typography>
        <div
          style={{ margin: "1rem", display: "flex", justifyContent: "center" }}
        >
          <AudioPlayer
            track={track}
            playerId={"audio__player"}
            openedAlert={openedAlert}
          />
        </div>
      </Grid>

      {((didSubmit && score) || forceShowAnswer) && (
        <Grid item xs={12}>
          <AlertSkillAnalysis
            uid={uid}
            score={score}
            keywords={keywords}
            setOpenedAlert={setOpenedAlert}
            speechTextArray={speechTextArray}
            sampleAnswer={correctAnswer || transcript}
            htmlJsx={`<p>${speechTextArray.join(" ")}</p>`}
          />
        </Grid>
      )}
      <RecorderManager question={question} openedAlert={openedAlert} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    display: "flex",
    margin: "1rem 0",
    maxWidth: "900px",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      margin: "0.5rem 0",
    },
  },
  column: {
    display: "flex",
    margin: "1rem 0.5rem",
    flexDirection: "column",
    justifyContent: "center",
  },
  taskMargin: {
    padding: "0 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  image: {
    width: "auto",
    height: "auto",
    maxWidth: "65vw",
    maxHeight: "65vh",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90vw",
      maxHeight: "60vh",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95vw",
      maxHeight: "50vh",
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  recorder: state.recorder,
  analysis: state.analysis,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseSpeakingSituation
);
