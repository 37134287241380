import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { toggleExamType } from "../../actions/userActions";
import { getExamTypeList } from "../../helpers/getExerciseData";
import { getExamType, isAuthenticated } from "../../helpers/userCheck";

// Drop down menu from User avatar
function ExamTypeMenu({ anchorEl, ...props }) {
  const classes = useStyles();

  // Tell parent to close menu
  function handleClose() {
    props.handleClose();
  }

  return (
    <Menu
      keepMounted
      elevation={0}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={Boolean(anchorEl)}
      MenuListProps={{ disablePadding: true }}
      PaperProps={{ style: { border: "1px solid #d3d4d5" } }}
    >
      <ClickAwayListener onClickAway={() => handleClose}>
        <div>
          <MenuList>
            {/* Menu items  */}
            {getExamTypeList.map((item, i) => {
              const isSelected = item === getExamType(props.user);

              return (
                <MenuItem
                  key={i}
                  onClick={() => {
                    props.toggleExamType({
                      examType: item,
                      isAuthenticated: isAuthenticated(props.user),
                    });
                    props.handleClose();
                  }}
                  className={clsx(classes.link, {
                    [classes.linkSelected]: isSelected,
                  })}
                >
                  <ListItemText
                    disableTypography
                    selected={isSelected}
                    primary={
                      <Typography type="body2" style={{ fontWeight: "bold" }}>
                        {item}
                      </Typography>
                    }
                  />
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
      </ClickAwayListener>
    </Menu>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    minWidth: "8rem",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 1),
    margin: theme.spacing(0.5, 0.5),
    "&:hover ": {
      borderRadius: "5px",
      color: theme.customColors.blackWhite,
      backgroundColor: theme.palette.action.hover,
    },
  },
  linkSelected: {
    color: "white",
    borderRadius: "5px",
    background: theme.customColors.blue,
  },
  linkHighlight: {
    borderRadius: "5px",
    border: `2px solid ${theme.customColors.blue}`,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { toggleExamType })(ExamTypeMenu);
