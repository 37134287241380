import { GET_PERKS } from "../helpers/types";

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PERKS:
      return action.payload;

    default:
      return state;
  }
}
