import Bowser from "bowser";
import { CHROME, SAFARI } from "./types";
var browser = window.navigator.userAgent;

// Return details about the browser
const getBrowserData = () => {
  return Bowser.parse(browser);
};
// Detect Safari
const isSafari = () => {
  return Bowser.parse(browser)?.browser?.name === SAFARI;
};

// Detect not Chrome
const isNotChrome = () => {
  return Bowser.parse(browser)?.browser?.name !== CHROME;
};

// iPad/iPhone detection
const isIOSMobile = () => {
  return browser.includes("Mac") && "ontouchend" in document;
};

// Detect desktop
const isDesktop = () => {
  return Bowser.parse(browser)?.platform?.type !== "mobile";
};

export { isIOSMobile, isSafari, isDesktop, isNotChrome, getBrowserData };
