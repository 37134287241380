import {
  RECORDER_END,
  SET_BLOB_URL,
  CLEAR_RECORDER,
  SET_SPEECH_TEXT,
  SET_CLICKED_NEXT,
  PLAYER_DID_FINISH,
  FORCE_SHOW_ANSWER,
  SET_SPEECH_TEXT_2,
  RECORDER_DID_FINISH,
  RECORDER_REVIEW_MODE,
  RECORDER_IS_RECORDING,
  RECORDER_TIME_WAITING,
  SET_BLOB_URL_OVERVIEW,
  RECORDER_ACCESS_DENIED,
  RECORDER_TIME_ANSWERING,
  RECORDER_TOGGLE_RECORDING,
  RECORDER_SILENCE_DETECTED,
} from "../helpers/types";
import { clearAnalysisScore } from "./analysisActions";

// @desc  Recorder permission denied
export const setAccessDenied = (data) => {
  return { type: RECORDER_ACCESS_DENIED, payload: data };
};

// @desc  Audio player finish
export const playerDidFinish = () => {
  return { type: PLAYER_DID_FINISH };
};

// @desc  Waiting timer BEFORE recording
export const setTimeLeftWaiting = (data) => {
  return { type: RECORDER_TIME_WAITING, payload: data };
};

// @desc  Waiting timer AFTER recording
export const setTimeLeftAnswering = (data) => {
  return { type: RECORDER_TIME_ANSWERING, payload: data };
};

// @desc  Audio player finish
export const setToggleRecording = (data) => (dispatch) => {
  dispatch({ type: RECORDER_TOGGLE_RECORDING, payload: data });
  if (data) dispatch(clearAnalysisScore());
};

// @desc  Trigger recorder submit
export const setRecorderDidEnd = (data) => {
  return { type: RECORDER_END, payload: data };
};

// @desc  Audio player finish
export const setIsRecording = (data) => {
  return { type: RECORDER_IS_RECORDING, payload: data };
};

// @desc  3 seconds silence detected
export const setSilenceDetected = () => {
  return { type: RECORDER_SILENCE_DETECTED };
};

// @desc  Audio recorder finish
export const setDidFinishRecording = (data) => {
  return { type: RECORDER_DID_FINISH, payload: data };
};

// @desc  Save speech text
export const setSpeechText = (data) => {
  return { type: SET_SPEECH_TEXT, payload: data };
};

// @desc  Save speech text (probability and averageWordsPerSec)
export const setSpeechText2 = (data) => {
  return { type: SET_SPEECH_TEXT_2, payload: data };
};

// @desc  Save blob url
export const setBlob = (data) => {
  return { type: SET_BLOB_URL, payload: data };
};

// @desc  Save blob url
export const setBlobOverview = (data) => {
  return { type: SET_BLOB_URL_OVERVIEW, payload: data };
};

// @desc  Force show answer
export const forceShowAnswer = (data) => {
  return { type: FORCE_SHOW_ANSWER, payload: data };
};

// @desc  Set review mode for recorder
export const setReviewMode = () => {
  return { type: RECORDER_REVIEW_MODE };
};

// @desc  Clear recorder
export const clearRecorderReducer = () => {
  return { type: CLEAR_RECORDER };
};

// @desc  Click next => stop recording
export const setClickedNext = (data) => {
  return { type: SET_CLICKED_NEXT, payload: data };
};
