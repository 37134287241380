import store from "../reducers/store";
import {
  ENABLING_DSC,
  ENABLING_GLR,
  ENABLING_VOC,
  ENABLING_FORM,
  ENABLING_FINAL,
  ENABLING_FLUENCY,
  ENABLING_CONTENT,
  ENABLING_GRAMMAR,
  ENABLING_SPELLING,
  ENABLING_EMAIL_CONV,
  ENABLING_APPROPRIACY,
  ENABLING_ORGANIZATION,
  ENABLING_PRONUNCIATION,
  ENABLING_NUM_SPELL_MISTAKE,
  ENABLING_NUM_GRAMMAR_MISTAKE,
} from "./types";
import {
  cleanScore,
  cleanString,
  getFluencyScore,
  cleanNumbersFromArray,
  cleanNumbersFromString,
  getPronunciationScore,
} from "./stringHelper";
import isDevMode from "./isDevMode";

import {
  setAnalysisScore,
  analyseAlertLoading,
} from "../actions/analysisActions";
import {
  setSpeechText,
  setSpeechText2,
  setToggleRecording,
} from "../actions/recorderActions";
import { setDidSubmit } from "../actions/questionActions";

const compromise = require("compromise");

const emailConvIntroWords = [
  "hello",
  "hi",
  "hey",
  "dear",
  "greetings",
  "morning",
  "afternoon",
  "evening",
  "to",
  "howdy",
  "salutations",
  "attention",
  "whom",
  "concern",
  "warm",
  "regards",
];

const emailConvClosingWords = [
  "kind",
  "best",
  "wishes",
  "regard",
  "regards",
  "warm",
  "warmth",
  "thank",
  "thanks",
  "see",
  "you",
  "cheers",
  "sincerely",
  "respectfully",
  "chat",
  "talk",
  "soon",
  "yours",
  "truly",
  "take",
  "care",
  "with",
  "appreciation",
  "faithfully",
  "cordially",
  "gratefully",
  "well",
  "deepest",
];

// Writing - Get score for writing essay
const getScoreForWritingEssay = (answer, analysis, topic) => {
  const corpus = compromise(answer);

  // Get errors stats
  const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
  const numOfGrammarMistakes = errors.filter(
    (error) => error.category === "grammargrammar" // Key from WritingProAid API
  ).length;
  const numOfSpellingMistakes = errors.filter(
    (error) => error.category === "grammarspelling" // Key from WritingProAid API
  ).length;

  // Get text stats
  const sentences = answer
    .split(".")
    .filter((sentence) => sentence)
    .map((sentence) => sentence.trim());

  const numOfSentences = sentences.length;
  const numOfParagraphs = analysis.Tags.filter(
    (error) => error.category === "paragraph"
  ).length;

  // Get word stats
  const numOfWords = analysis.WordCount;
  const numOfNouns = corpus.nouns().list.length;
  const numOfVerbs = corpus.verbs().list.length;
  const numOfAdverbs = corpus.adverbs().list.length;
  const numOfAdjectives = corpus.adjectives().list.length;
  const numOfPossessives = corpus.possessives().list.length;
  const numOfComplexWords = analysis.Tags.filter(
    (tag) => tag.report === "complex"
  ).length;
  const numOfTransitionWords = analysis.Tags.filter(
    (tag) => tag.report === "transition"
  ).length;

  // Check unique words
  const uniqueWords = Array.from(new Set(answer.split(/\s+/)));
  const numOfUniqueWords = uniqueWords.length;

  // Check if answer use topic keywords
  const cleanedTopicWords = topic.split(/\s+/).map((word) => cleanString(word));
  const numOfMatchingWords = uniqueWords.filter(
    (word) => word.length >= 4 && cleanedTopicWords.includes(cleanString(word))
  ).length;

  // Check if all in capital letters
  const isInCapitalLetter = answer === answer.toUpperCase();

  var scoreVoc = 0;
  var scoreGlr = 0;
  var scoreDsc = 0;
  var scoreForm = 0;
  var scoreGrammar = 0;
  var scoreContent = 0;
  var scoreSpelling = 0;

  var scoreFinal = 0;

  // Score form: 200 to 300 words for 2 points
  if (numOfWords < 120 || numOfWords > 380 || isInCapitalLetter) {
    scoreForm = 0;
  } else if (
    (numOfWords >= 120 && numOfWords <= 199) ||
    (numOfWords >= 301 && numOfWords <= 380)
  ) {
    scoreForm = 1;
  } else if (numOfWords >= 200 && numOfWords <= 300) {
    scoreForm = 2;
  }

  // Spelling score : 0 mistake for 2 points
  if (numOfSpellingMistakes === 0) {
    scoreSpelling = 2;
  } else if (numOfSpellingMistakes === 1) {
    scoreSpelling = 1;
  }

  // Grammar score : Less than 5 mistakes for 2 points
  if (numOfGrammarMistakes >= 5 && numOfGrammarMistakes <= 8) {
    scoreGrammar = 1;
  } else if (numOfGrammarMistakes < 5) {
    scoreGrammar = 2;
  }

  // Content score : 10 matching words with topic for 2 points
  if (numOfMatchingWords >= 10) {
    scoreContent = 2;
  } else if (numOfMatchingWords >= 2) {
    scoreContent = 1;
  }

  // Voc score : a good mix of word types for 2 points
  if (numOfUniqueWords >= 100) {
    scoreVoc = 2;
  } else if (numOfUniqueWords >= 40) {
    scoreVoc = 1;
  }

  // GLR score : a good mix of word types for 2 points
  if (
    numOfUniqueWords >= 80 &&
    numOfNouns >= 25 &&
    numOfVerbs >= 10 &&
    numOfAdverbs >= 2 &&
    numOfAdjectives >= 2 &&
    numOfPossessives >= 2 &&
    numOfComplexWords >= 2
  ) {
    scoreGlr = 2;
  } else if (
    numOfUniqueWords >= 50 &&
    numOfNouns >= 10 &&
    numOfVerbs >= 5 &&
    numOfAdverbs >= 1 &&
    numOfAdjectives >= 1
  ) {
    scoreGlr = 1;
  }

  // DSC score : a good mix of word types for 2 points
  if (
    numOfParagraphs >= 2 &&
    numOfParagraphs <= 6 &&
    numOfTransitionWords >= 8 &&
    numOfSentences >= 8
  ) {
    scoreDsc = 2;
  } else if (
    numOfParagraphs >= 2 &&
    numOfTransitionWords > 3 &&
    numOfSentences > 5
  ) {
    scoreDsc = 1;
  }

  // Final score
  scoreFinal =
    scoreForm +
    scoreVoc +
    scoreGrammar +
    scoreContent +
    scoreSpelling +
    scoreDsc +
    scoreGlr;

  // Score form = 0 => Everything is equal 0 (official PTE rule)
  if (scoreForm === 0) {
    scoreDsc = 0;
    scoreGlr = 0;
    scoreVoc = 0;
    scoreFinal = 0;
    scoreGrammar = 0;
    scoreContent = 0;
    scoreSpelling = 0;
  }

  const data = {
    [ENABLING_DSC]: scoreDsc,
    [ENABLING_GLR]: scoreGlr,
    [ENABLING_VOC]: scoreVoc,
    [ENABLING_FORM]: scoreForm,
    [ENABLING_FINAL]: scoreFinal,
    [ENABLING_CONTENT]: scoreContent,
    [ENABLING_GRAMMAR]: scoreGrammar,
    [ENABLING_SPELLING]: scoreSpelling,
    [ENABLING_NUM_SPELL_MISTAKE]: numOfSpellingMistakes,
    [ENABLING_NUM_GRAMMAR_MISTAKE]: numOfGrammarMistakes,
  };

  // Send to analysis reducer
  return store.dispatch(setAnalysisScore(data));
};

// Writing - Get score for summarize written text
const getScoreForWritingSummarize = (answer, analysis, topic) => {
  const corpus = compromise(answer);

  // Get errors stats
  const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
  const numOfGrammarMistakes = errors.filter(
    (error) => error.category === "grammargrammar" // Key from WritingProAid API
  ).length;

  // Get text stats
  const sentences = answer
    .trim()
    .split(".")
    .filter((sentence) => sentence)
    .map((sentence) => sentence.trim());
  const numOfSentences = sentences.length;

  // Get word stats
  const numOfWords = analysis.WordCount;
  const numOfNouns = corpus.nouns().list.length;
  const numOfVerbs = corpus.verbs().list.length;
  const numOfAdverbs = corpus.adverbs().list.length;
  const numOfAdjectives = corpus.adjectives().list.length;

  const numOfTransitionWords = analysis.Tags.filter(
    (tag) => tag.report === "transition"
  ).length;

  // Check unique words
  const uniqueWords = Array.from(new Set(answer.split(/\s+/)));
  const numOfUniqueWords = uniqueWords.length;

  // Check if answer use topic keywords
  const cleanedTopicWords = topic.split(/\s+/).map((word) => cleanString(word));
  const numOfMatchingWords = uniqueWords.filter(
    (word) => word.length >= 4 && cleanedTopicWords.includes(cleanString(word))
  ).length;

  // Check if student copy pasted the topic
  const isCopyPasteTopic =
    (100 *
      answer
        .split(/\s+/)
        .filter((word) => cleanedTopicWords.includes(cleanString(word)))
        .length) /
      numOfWords >
    99;

  if (isDevMode()) {
    console.log("=== [DEV] ===");
    console.log("isCopyPasteTopic " + isCopyPasteTopic);
    console.log("numOfWords " + numOfWords);
    console.log("numOfUniqueWords " + numOfUniqueWords);
    console.log("numOfMatchingWords " + numOfMatchingWords);
    console.log("numOfNouns " + numOfNouns);
    console.log("numOfVerbs " + numOfVerbs);
    console.log("numOfAdverbs " + numOfAdverbs);
    console.log("numOfAdjectives " + numOfAdjectives);
    console.log("numOfTransitionWords " + numOfTransitionWords);
    console.log("numOfGrammarMistakes " + numOfGrammarMistakes);
  }

  // Check if all in capital letters
  const isInCapitalLetter = answer === answer.toUpperCase();

  // Check if starts and ends with capital letter and full stop
  const trimmedAnswer = answer ? answer.trim() : " ";
  const startWithCapitalLetter =
    trimmedAnswer[0] === trimmedAnswer[0].toUpperCase();
  const endWithFullStop = trimmedAnswer[trimmedAnswer.length - 1] === ".";

  var scoreVoc = 0;
  var scoreForm = 0;
  var scoreGrammar = 0;
  var scoreContent = 0;

  var scoreFinal = 0;

  // Score form: Single sentence of 5 to 75 words for 1 point
  if (
    numOfWords >= 5 &&
    numOfWords <= 75 &&
    numOfSentences === 1 &&
    startWithCapitalLetter &&
    endWithFullStop &&
    !isInCapitalLetter
  ) {
    scoreForm = 1;
  }

  // Grammar score : Less than 3 mistakes for 2 points
  if (numOfGrammarMistakes >= 3 && numOfGrammarMistakes <= 5) {
    scoreGrammar = 1;
  } else if (numOfGrammarMistakes < 3) {
    scoreGrammar = 2;
  }

  // Content score : 10 matching words with topic for 2 points
  if (numOfMatchingWords >= 10) {
    scoreContent = 2;
  } else if (numOfMatchingWords >= 5) {
    scoreContent = 1;
  }

  // Voc score : a good mix of word types for 2 points
  if (
    numOfUniqueWords >= 20 &&
    numOfNouns >= 5 &&
    numOfVerbs >= 2 &&
    (numOfAdverbs >= 1 || numOfAdjectives >= 1 || numOfTransitionWords >= 1)
  ) {
    scoreVoc = 2;
  } else if (numOfUniqueWords >= 10 && numOfNouns >= 2 && numOfVerbs >= 1) {
    scoreVoc = 1;
  }

  // Final score
  scoreFinal = scoreForm + scoreVoc + scoreGrammar + scoreContent;

  // Score form = 0 => Everything is equal 0 (official PTE rule)
  if (numOfWords < 5 || numOfWords > 75 || isCopyPasteTopic) {
    scoreVoc = 0;
    scoreForm = 0;
    scoreFinal = 0;
    scoreGrammar = 0;
    scoreContent = 0;
  }

  const data = {
    [ENABLING_VOC]: scoreVoc,
    [ENABLING_FORM]: scoreForm,
    [ENABLING_FINAL]: scoreFinal,
    [ENABLING_CONTENT]: scoreContent,
    [ENABLING_GRAMMAR]: scoreGrammar,
    [ENABLING_NUM_GRAMMAR_MISTAKE]: numOfGrammarMistakes,
  };

  // Send to analysis reducer
  return store.dispatch(setAnalysisScore(data));
};

// Writing - Get score for summarize written text
const getScoreForWritingSummarizeCore = (answer, analysis, topic) => {
  const corpus = compromise(answer);

  // Get errors stats
  const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
  const numOfGrammarMistakes = errors.filter(
    (error) => error.category === "grammargrammar" // Key from WritingProAid API
  ).length;

  // Get text stats
  const sentences = answer.trim().match(/[^\.!\?]+[\.!\?]+/g);

  // Get word stats
  const numOfWords = analysis.WordCount;
  const numOfNouns = corpus.nouns().list.length;
  const numOfVerbs = corpus.verbs().list.length;
  const numOfAdverbs = corpus.adverbs().list.length;
  const numOfAdjectives = corpus.adjectives().list.length;

  const numOfTransitionWords = analysis.Tags.filter(
    (tag) => tag.report === "transition"
  ).length;

  // Check unique words
  const uniqueWords = Array.from(new Set(answer.split(/\s+/)));
  const numOfUniqueWords = uniqueWords.length;

  // Check if answer use topic keywords
  const cleanedTopicWords = topic.split(/\s+/).map((word) => cleanString(word));
  const numOfMatchingWords = answer
    .split(/\s+/)
    .filter(
      (word) =>
        word.length >= 4 && cleanedTopicWords.includes(cleanString(word))
    ).length;

  // Check if student copy pasted the topic
  const isCopyPasteTopic =
    (100 *
      answer
        .split(/\s+/)
        .filter((word) => cleanedTopicWords.includes(cleanString(word)))
        .length) /
      numOfWords >
    99;
  // Check if all in capital letters
  const isInCapitalLetter = answer === answer.toUpperCase();

  // Check if starts and ends with capital letter and full stop
  const trimmedAnswer = answer ? answer.trim() : " ";
  const startWithCapitalLetter =
    trimmedAnswer[0] === trimmedAnswer[0].toUpperCase();
  const answerEndWithFullStop = trimmedAnswer.endsWith(".");
  const allSentencesEndWithFullStop = sentences.every((s) =>
    s.trim().endsWith(".")
  );

  var scoreVoc = 0;
  var scoreForm = 0;
  var scoreGrammar = 0;
  var scoreContent = 0;

  var scoreFinal = 0;

  if (isDevMode()) {
    console.log("=== [DEV] ===");
    console.log("isCopyPasteTopic " + isCopyPasteTopic);
    console.log("numOfWords " + numOfWords);
    console.log("numOfUniqueWords " + numOfUniqueWords);
    console.log("numOfMatchingWords " + numOfMatchingWords);
    console.log("numOfNouns " + numOfNouns);
    console.log("numOfVerbs " + numOfVerbs);
    console.log("numOfAdverbs " + numOfAdverbs);
    console.log("numOfAdjectives " + numOfAdjectives);
    console.log("numOfTransitionWords " + numOfTransitionWords);
    console.log("numOfGrammarMistakes " + numOfGrammarMistakes);
  }

  // Score form: 25 to 50 words for 2 points
  if (
    numOfWords < 5 ||
    numOfWords > 60 ||
    !startWithCapitalLetter ||
    !(answerEndWithFullStop && allSentencesEndWithFullStop) ||
    isInCapitalLetter
  ) {
    scoreForm = 0;
  } else if (
    (numOfWords >= 5 && numOfWords <= 24) ||
    (numOfWords >= 51 && numOfWords <= 60)
  ) {
    scoreForm = 1;
  } else if (numOfWords >= 25 && numOfWords <= 50) {
    scoreForm = 2;
  }

  // Grammar score : Less than 3 mistakes for 2 points
  if (numOfGrammarMistakes >= 3 && numOfGrammarMistakes <= 5) {
    scoreGrammar = 1;
  } else if (numOfGrammarMistakes < 3) {
    scoreGrammar = 2;
  }

  // Content score : 12 matching words with topic for 2 points
  if (numOfMatchingWords >= 12) {
    scoreContent = 2;
  } else if (numOfMatchingWords >= 8) {
    scoreContent = 1;
  }

  if (isCopyPasteTopic) {
    scoreContent = 0;
  }

  // Voc score : a good mix of word types for 2 points
  if (
    numOfUniqueWords >= 20 &&
    numOfNouns >= 8 &&
    numOfVerbs >= 4 &&
    numOfAdverbs >= 1 &&
    numOfAdjectives >= 1 &&
    numOfTransitionWords >= 1
  ) {
    scoreVoc = 2;
  } else if (numOfUniqueWords >= 10 && numOfNouns >= 5 && numOfVerbs >= 1) {
    scoreVoc = 1;
  }

  // Final score
  scoreFinal = scoreForm + scoreVoc + scoreGrammar + scoreContent;

  // Score content = 0 => Everything is equal 0 (official PTE rule)
  if (scoreContent === 0) {
    scoreVoc = 0;
    scoreForm = 0;
    scoreFinal = 0;
    scoreGrammar = 0;
    scoreContent = 0;
  }

  const data = {
    [ENABLING_VOC]: scoreVoc,
    [ENABLING_FORM]: scoreForm,
    [ENABLING_FINAL]: scoreFinal,
    [ENABLING_CONTENT]: scoreContent,
    [ENABLING_GRAMMAR]: scoreGrammar,
    [ENABLING_NUM_GRAMMAR_MISTAKE]: numOfGrammarMistakes,
  };

  // Send to analysis reducer
  return store.dispatch(setAnalysisScore(data));
};

// Writing - Get score for writing email
const getScoreForWritingEmail = (answer, analysis, question) => {
  const corpus = compromise(answer);

  // Get errors stats
  const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
  const numOfGrammarMistakes = errors.filter(
    (error) => error.category === "grammargrammar" // Key from WritingProAid API
  ).length;
  const numOfSpellingMistakes = errors.filter(
    (error) => error.category === "grammarspelling" // Key from WritingProAid API
  ).length;

  // Get text stats
  const sentences = answer
    .split(".")
    .filter((sentence) => sentence)
    .map((sentence) => sentence.trim());

  const numOfSentences = sentences.length;
  const numOfParagraphs = analysis.Tags.filter(
    (error) => error.category === "paragraph"
  ).length;

  // Get word stats
  const numOfWords = analysis.WordCount;
  const numOfNouns = corpus.nouns().list.length;
  const numOfVerbs = corpus.verbs().list.length;
  const numOfPeople = corpus.people().list.length;
  const numOfAdverbs = corpus.adverbs().list.length;
  const numOfAdjectives = corpus.adjectives().list.length;
  const numOfPossessives = corpus.possessives().list.length;
  const numOfComplexWords = analysis.Tags.filter(
    (tag) => tag.report === "complex"
  ).length;
  const numOfTransitionWords = analysis.Tags.filter(
    (tag) => tag.report === "transition"
  ).length;

  // Check unique words
  const uniqueWords = Array.from(new Set(answer.split(/\s+/)));
  const numOfUniqueWords = uniqueWords.length;

  // Check if answer use topic keywords
  var numOfMatchingWords = 0;
  var numOfMatchingTask = 0;

  // Check email convention
  var numOfIntroWords = 0;
  var numOfClosingWords = 0;

  const keywords = question?.keywords?.slice();
  const keywordsTask = question?.taskKeywords?.slice();
  // Check similar words and remove them
  uniqueWords.map((word, i) => {
    var cleanWord = cleanString(word);
    if (keywords.includes(cleanWord)) {
      const indexToRemove = keywords.indexOf(cleanWord);
      keywords.splice(indexToRemove, 1, "WORD_REMOVED");
      numOfMatchingWords += 1;
    }
    if (keywordsTask.includes(cleanWord)) {
      const indexToRemove = keywordsTask.indexOf(cleanWord);
      keywordsTask.splice(indexToRemove, 1, "WORD_REMOVED");
      numOfMatchingTask += 1;
    }

    if (i < 5 && emailConvIntroWords.includes(cleanWord)) {
      numOfIntroWords += 1;
    }

    if (
      numOfUniqueWords - i < 10 &&
      emailConvClosingWords.includes(cleanWord)
    ) {
      numOfClosingWords += 1;
    }
  });

  const percentageKeywords = (numOfMatchingWords * 100) / keywords.length;
  const percentageKeywordsTask =
    (numOfMatchingTask * 100) / keywordsTask.length;

  // Check if all in capital letters
  const isInCapitalLetter = answer === answer.toUpperCase();

  var scoreOrg = 0;
  var scoreVoc = 0;
  var scoreForm = 0;
  var scoreEmail = 0;
  var scoreGrammar = 0;
  var scoreContent = 0;
  var scoreSpelling = 0;

  var scoreFinal = 0;

  // Score form: 50 to 120 words for 2 points
  if (numOfWords < 30 || numOfWords > 140 || isInCapitalLetter) {
    scoreForm = 0;
  } else if (
    (numOfWords >= 30 && numOfWords <= 49) ||
    (numOfWords >= 121 && numOfWords <= 140)
  ) {
    scoreForm = 1;
  } else if (numOfWords >= 50 && numOfWords <= 120) {
    scoreForm = 2;
  }

  // Spelling score : 2 mistakes or less for 2 points
  if (numOfSpellingMistakes <= 2) {
    scoreSpelling = 2;
  } else if (numOfSpellingMistakes === 3 || numOfSpellingMistakes === 4) {
    scoreSpelling = 1;
  }

  // Grammar score : Less than 4 mistakes for 2 points
  if (numOfGrammarMistakes >= 4 && numOfGrammarMistakes <= 6) {
    scoreGrammar = 1;
  } else if (numOfGrammarMistakes < 4) {
    scoreGrammar = 2;
  }

  // Content score : 75% matching keywords for 3 points
  if (percentageKeywordsTask >= 75 && percentageKeywords >= 60) {
    scoreContent = 3;
  } else if (percentageKeywordsTask >= 50 && percentageKeywords >= 40) {
    scoreContent = 2;
  } else if (percentageKeywordsTask >= 25 && percentageKeywords >= 20) {
    scoreContent = 1;
  }

  if (isDevMode()) {
    console.log("=== [DEV] ===");
    console.log("numOfWords " + numOfWords);
    console.log("numOfParagraphs " + numOfParagraphs);
    console.log("numOfSentences " + numOfSentences);
    console.log("percentageKeywordsTask " + percentageKeywordsTask);
    console.log("percentageKeywords " + percentageKeywords);
    console.log("numOfUniqueWords " + numOfUniqueWords);
    console.log("numOfNouns " + numOfNouns);
    console.log("numOfVerbs " + numOfVerbs);
    console.log("numOfAdverbs " + numOfAdverbs);
    console.log("numOfAdjectives " + numOfAdjectives);
    console.log("numOfTransitionWords " + numOfTransitionWords);
    console.log("numOfComplexWords " + numOfComplexWords);
    console.log("numOfPossessives " + numOfPossessives);
    console.log("numOfIntroWords " + numOfIntroWords);
    console.log("numOfClosingWords " + numOfClosingWords);
    console.log("numOfGrammarMistakes " + numOfGrammarMistakes);
    console.log("numOfSpellingMistakes " + numOfSpellingMistakes);
    console.log("numOfPeople " + numOfPeople);
  }

  // Content email : 75% matching keywords for 3 points
  if (numOfIntroWords >= 1 && numOfClosingWords >= 1 && numOfPeople >= 1) {
    scoreEmail = 2;
  } else if (numOfIntroWords >= 1 || numOfClosingWords >= 1) {
    scoreEmail = 1;
  }

  // Voc score : a good mix of word types for 2 points
  if (
    numOfUniqueWords >= 50 &&
    numOfNouns >= 15 &&
    numOfVerbs >= 15 &&
    numOfAdverbs >= 1 &&
    numOfAdjectives >= 3 &&
    numOfTransitionWords >= 1 &&
    numOfComplexWords >= 1 &&
    numOfPossessives >= 1
  ) {
    scoreVoc = 2;
  } else if (
    numOfUniqueWords >= 30 &&
    numOfNouns >= 5 &&
    numOfVerbs >= 5 &&
    numOfAdjectives >= 1
  ) {
    scoreVoc = 1;
  }

  // Score Organization: 4 to 7 paragraphs and 3 transition words for 2 points
  if (
    numOfParagraphs >= 4 &&
    numOfParagraphs <= 8 &&
    numOfTransitionWords >= 2 &&
    numOfSentences >= 7
  ) {
    scoreOrg = 2;
  } else if (
    (numOfParagraphs >= 2 && numOfParagraphs <= 3) ||
    (numOfParagraphs >= 9 && numOfParagraphs <= 10) ||
    numOfTransitionWords === 1 ||
    (numOfParagraphs >= 4 && numOfParagraphs <= 8 && numOfSentences >= 5)
  ) {
    scoreOrg = 1;
  } else if (
    numOfParagraphs < 2 ||
    numOfParagraphs > 10 ||
    numOfTransitionWords === 0
  ) {
    scoreOrg = 0;
  }

  // Score form = 0 or content = 0 => Everything is equal 0 (official PTE rule)
  if (scoreForm === 0 || scoreContent === 0) {
    scoreOrg = 0;
    scoreVoc = 0;
    scoreForm = 0;
    scoreEmail = 0;
    scoreFinal = 0;
    scoreGrammar = 0;
    scoreContent = 0;
    scoreSpelling = 0;
  }

  // Score email = 0 => Reduce score content
  if (scoreEmail === 0 && scoreContent >= 1) {
    scoreContent = 1;
  }

  // Final score
  scoreFinal =
    scoreForm +
    scoreVoc +
    scoreGrammar +
    scoreContent +
    scoreSpelling +
    scoreEmail +
    scoreOrg;

  const data = {
    [ENABLING_VOC]: scoreVoc,
    [ENABLING_FORM]: scoreForm,
    [ENABLING_FINAL]: scoreFinal,
    [ENABLING_CONTENT]: scoreContent,
    [ENABLING_GRAMMAR]: scoreGrammar,
    [ENABLING_EMAIL_CONV]: scoreEmail,
    [ENABLING_ORGANIZATION]: scoreOrg,
    [ENABLING_SPELLING]: scoreSpelling,
    [ENABLING_NUM_SPELL_MISTAKE]: numOfSpellingMistakes,
    [ENABLING_NUM_GRAMMAR_MISTAKE]: numOfGrammarMistakes,
  };

  // Send to analysis reducer
  return store.dispatch(setAnalysisScore(data));
};

// Listening - Get score for summarize spoken text
const getScoreForListeningSummarize = (answer, analysis, transcript) => {
  const corpus = compromise(answer);

  // Get errors stats
  const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
  const numOfGrammarMistakes = errors.filter(
    (error) => error.category === "grammargrammar" // Key from WritingProAid API
  ).length;
  const numOfSpellingMistakes = errors.filter(
    (error) => error.category === "grammarspelling" // Key from WritingProAid API
  ).length;

  // Get text stats
  const sentences = answer
    .split(".")
    .filter((sentence) => sentence)
    .map((sentence) => sentence.trim());

  const numOfSentences = sentences.length;
  const numOfParagraphs = analysis.Tags.filter(
    (error) => error.category === "paragraph"
  ).length;

  // Get word stats
  const numOfWords = analysis.WordCount;
  const numOfNouns = corpus.nouns().list.length;
  const numOfVerbs = corpus.verbs().list.length;
  const numOfAdverbs = corpus.adverbs().list.length;
  const numOfAdjectives = corpus.adjectives().list.length;

  // Check unique words
  const uniqueWords = Array.from(new Set(answer.split(/\s+/)));
  const numOfUniqueWords = uniqueWords.length;

  // Check if answer use transcript keywords
  const cleanedTopicWords = transcript
    .split(/\s+/)
    .map((word) => cleanString(word));
  const numOfMatchingWords = uniqueWords.filter(
    (word) => word.length >= 4 && cleanedTopicWords.includes(cleanString(word))
  ).length;

  // Check if all in capital letters
  const isInCapitalLetter = answer === answer.toUpperCase();
  // Check if it has no full stop at all
  const hasNoFullStops = !answer.includes(".");

  var scoreVoc = 0;
  var scoreForm = 0;
  var scoreGrammar = 0;
  var scoreContent = 0;
  var scoreSpelling = 0;

  var scoreFinal = 0;

  // Score form: 40 to 70 words for 2 points
  if (
    numOfWords < 40 ||
    numOfWords > 100 ||
    // Requierements no short sentences and no bullet points
    numOfSentences > 8 ||
    numOfParagraphs > 4 ||
    isInCapitalLetter ||
    hasNoFullStops
  ) {
    scoreForm = 0;
  } else if (
    (numOfWords >= 40 && numOfWords <= 49) ||
    (numOfWords >= 71 && numOfWords <= 100)
  ) {
    scoreForm = 1;
  } else if (numOfWords >= 50 && numOfWords <= 70) {
    scoreForm = 2;
  }

  // Spelling score : 0 mistake for 2 points
  if (numOfSpellingMistakes === 0) {
    scoreSpelling = 2;
  } else if (numOfSpellingMistakes === 1) {
    scoreSpelling = 1;
  }

  // Grammar score : Less than 4 mistakes for 2 points
  if (numOfGrammarMistakes >= 4 && numOfGrammarMistakes <= 6) {
    scoreGrammar = 1;
  } else if (numOfGrammarMistakes < 4) {
    scoreGrammar = 2;
  }

  // Content score : 10 matching words with transcript for 2 points
  if (numOfMatchingWords >= 10) {
    scoreContent = 2;
  } else if (numOfMatchingWords >= 2) {
    scoreContent = 1;
  }

  // Voc score : a good mix of word types for 2 points
  if (
    numOfUniqueWords >= 60 &&
    numOfNouns >= 10 &&
    numOfVerbs >= 5 &&
    numOfAdverbs >= 2 &&
    numOfAdjectives >= 1
  ) {
    scoreVoc = 2;
  } else if (
    numOfUniqueWords >= 30 &&
    numOfNouns >= 5 &&
    numOfVerbs >= 1 &&
    numOfAdverbs >= 1 &&
    numOfAdjectives >= 1
  ) {
    scoreVoc = 1;
  }

  // Final score
  scoreFinal =
    scoreForm + scoreVoc + scoreGrammar + scoreContent + scoreSpelling;

  // Score form = 0 => Everything is equal 0 (official PTE rule)
  if (numOfWords < 40 || numOfWords > 100) {
    scoreVoc = 0;
    scoreFinal = 0;
    scoreGrammar = 0;
    scoreContent = 0;
    scoreSpelling = 0;
  }

  const data = {
    [ENABLING_VOC]: scoreVoc,
    [ENABLING_FORM]: scoreForm,
    [ENABLING_FINAL]: scoreFinal,
    [ENABLING_CONTENT]: scoreContent,
    [ENABLING_GRAMMAR]: scoreGrammar,
    [ENABLING_SPELLING]: scoreSpelling,
    [ENABLING_NUM_SPELL_MISTAKE]: numOfSpellingMistakes,
    [ENABLING_NUM_GRAMMAR_MISTAKE]: numOfGrammarMistakes,
  };

  // Send to analysis reducer
  return store.dispatch(setAnalysisScore(data));
};

// Listening - Get score for summarize spoken text (CORE)
const getScoreForListeningSummarizeCore = (answer, analysis, transcript) => {
  const corpus = compromise(answer);
  // Get errors stats
  const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
  const numOfGrammarMistakes = errors.filter(
    (error) => error.category === "grammargrammar" // Key from WritingProAid API
  ).length;
  const numOfSpellingMistakes = errors.filter(
    (error) => error.category === "grammarspelling" // Key from WritingProAid API
  ).length;

  // Get text stats
  const sentences = answer
    .split(".")
    .filter((sentence) => sentence)
    .map((sentence) => sentence.trim());

  const numOfSentences = sentences.length;
  const numOfParagraphs = analysis.Tags.filter(
    (error) => error.category === "paragraph"
  ).length;

  // Get word stats
  const numOfWords = analysis.WordCount;
  const numOfNouns = corpus.nouns().list.length;
  const numOfVerbs = corpus.verbs().list.length;
  const numOfAdverbs = corpus.adverbs().list.length;
  const numOfAdjectives = corpus.adjectives().list.length;

  // Check unique words
  const uniqueWords = Array.from(new Set(answer.split(/\s+/)));
  const numOfUniqueWords = uniqueWords.length;

  // Check if answer use transcript keywords
  const cleanedTopicWords = transcript
    .split(/\s+/)
    .map((word) => cleanString(word));
  const numOfMatchingWords = uniqueWords.filter(
    (word) => word.length >= 4 && cleanedTopicWords.includes(cleanString(word))
  ).length;

  // Check if all in capital letters
  const isInCapitalLetter = answer === answer.toUpperCase();
  // Check if it has no full stop at all
  const hasNoFullStops = !answer.includes(".");

  var scoreVoc = 0;
  var scoreForm = 0;
  var scoreGrammar = 0;
  var scoreContent = 0;
  var scoreSpelling = 0;

  var scoreFinal = 0;

  // Score form: 20 to 30 words for 2 points
  if (
    numOfWords < 5 ||
    numOfWords > 40 ||
    // Requierements no short sentences and no bullet points
    numOfSentences > 8 ||
    numOfParagraphs > 4 ||
    isInCapitalLetter ||
    hasNoFullStops
  ) {
    scoreForm = 0;
  } else if (
    (numOfWords >= 5 && numOfWords <= 19) ||
    (numOfWords >= 31 && numOfWords <= 40)
  ) {
    scoreForm = 1;
  } else if (numOfWords >= 20 && numOfWords <= 30) {
    scoreForm = 2;
  }

  // Spelling score : 0 mistake for 2 points
  if (numOfSpellingMistakes === 0) {
    scoreSpelling = 2;
  } else if (numOfSpellingMistakes === 1) {
    scoreSpelling = 1;
  }

  // Grammar score : Less than 4 mistakes for 2 points
  if (numOfGrammarMistakes >= 4 && numOfGrammarMistakes <= 6) {
    scoreGrammar = 1;
  } else if (numOfGrammarMistakes < 4) {
    scoreGrammar = 2;
  }

  // Content score : 10 matching words with transcript for 2 points
  if (numOfMatchingWords >= 10) {
    scoreContent = 2;
  } else if (numOfMatchingWords >= 2) {
    scoreContent = 1;
  }

  // Voc score : a good mix of word types for 2 points
  if (
    numOfUniqueWords >= 20 &&
    numOfNouns >= 5 &&
    numOfVerbs >= 3 &&
    (numOfAdverbs >= 1 || numOfAdjectives >= 1)
  ) {
    scoreVoc = 2;
  } else if (numOfUniqueWords >= 10 && numOfNouns >= 3 && numOfVerbs >= 1) {
    scoreVoc = 1;
  }

  // Final score
  scoreFinal =
    scoreForm + scoreVoc + scoreGrammar + scoreContent + scoreSpelling;

  // Score form = 0 => Everything is equal 0 (official PTE rule)
  if (numOfWords < 5 || numOfWords > 40) {
    scoreVoc = 0;
    scoreFinal = 0;
    scoreGrammar = 0;
    scoreContent = 0;
    scoreSpelling = 0;
  }

  const data = {
    [ENABLING_VOC]: scoreVoc,
    [ENABLING_FORM]: scoreForm,
    [ENABLING_FINAL]: scoreFinal,
    [ENABLING_CONTENT]: scoreContent,
    [ENABLING_GRAMMAR]: scoreGrammar,
    [ENABLING_SPELLING]: scoreSpelling,
    [ENABLING_NUM_SPELL_MISTAKE]: numOfSpellingMistakes,
    [ENABLING_NUM_GRAMMAR_MISTAKE]: numOfGrammarMistakes,
  };

  // Send to analysis reducer
  return store.dispatch(setAnalysisScore(data));
};

// Speaking - Get score for Repeat Sentences or Short questions
const getScoreForSpeaking = (
  speechText,
  question,
  isReviewMode,
  isMockTest,
  probability = 0,
  averageWordsPerSec = 0
) => {
  store.dispatch(setDidSubmit(true));
  store.dispatch(setToggleRecording(false));
  store.dispatch(setSpeechText([]));
  store.dispatch(
    setSpeechText2({ probability: 0, averageWordsPerSec: 0, speechText: [] })
  );

  // Don't trigger loading on mock / review mode
  if (!isReviewMode && !isMockTest) {
    store.dispatch(analyseAlertLoading(true));
  }

  const isShortQuestion = question.uid.includes("SSQ");
  const correctAnswer = isShortQuestion
    ? question.correctAnswers || question.correctAnswer
    : question.transcript;

  // No speech
  if (!speechText[0]) {
    const data = {
      [ENABLING_FINAL]: 0,
      [ENABLING_CONTENT]: 0,
      [ENABLING_FLUENCY]: 0,
      [ENABLING_APPROPRIACY]: 0,
      [ENABLING_PRONUNCIATION]: 0,
    };

    // Send to analysis/recorder reducer
    store.dispatch(setAnalysisScore(data));
    store.dispatch(analyseAlertLoading(false));
    return;
  }

  if (question.uid.includes("SSQ")) {
    // Short question exercise
    var isCorrect;
    if (typeof correctAnswer === "object") {
      isCorrect =
        correctAnswer.filter((answer) =>
          cleanString(speechText[0]).includes(answer.toLowerCase())
        ).length > 0;
    } else {
      isCorrect = cleanString(speechText[0]).includes(
        correctAnswer.toLowerCase()
      );
    }

    const data = {
      [ENABLING_FINAL]: isCorrect ? 1 : 0,
      [ENABLING_CONTENT]: isCorrect ? 1 : 0,
    };

    // Send to analysis/recorder reducer
    store.dispatch(setSpeechText([speechText[0]]));
    store.dispatch(
      setSpeechText2({
        probability,
        averageWordsPerSec,
        speechText: [speechText[0]],
      })
    );
    store.dispatch(setAnalysisScore(data));
    store.dispatch(analyseAlertLoading(false));
    return;
  } else if (question.uid.includes("SRS")) {
    // Repeat sentence exercise
    var score = 0;
    var contentScore = 0;

    const realWords = correctAnswer.split(/\s+/).map((w) => cleanString(w));
    const speechTextArray = cleanNumbersFromArray(
      speechText[0].split(/\s+/)
    ).map((w) => cleanString(w));

    // Check similar words and remove them
    realWords.map((word) => {
      if (speechTextArray.includes(word)) {
        const indexToRemove = speechTextArray.indexOf(word);
        speechTextArray.splice(indexToRemove, 1, "WORD_REMOVED");
        score += 1;
      }
    });

    // Temp scores
    const tempPercentage = Math.min(
      Math.round((score * 100) / realWords.length),
      100
    );

    // Content score
    if (tempPercentage <= 10) {
      contentScore = 0;
    } else if (tempPercentage < 50) {
      contentScore = 1;
    } else if (tempPercentage < 100) {
      contentScore = 2;
    } else if (tempPercentage >= 100) {
      contentScore = 3;
    }

    const fluencyScore = getFluencyScore(averageWordsPerSec);
    const pronunciationScore = getPronunciationScore(probability, fluencyScore);

    // Give more weight to content in final score
    var finalScore = contentScore + fluencyScore + pronunciationScore;

    // Prevent too high score if bad contentScore
    if (contentScore <= 1)
      finalScore = Math.round(
        ((contentScore * 5 + fluencyScore + pronunciationScore) / 25) * 13
      );

    const data = {
      [ENABLING_FINAL]: finalScore,
      [ENABLING_CONTENT]: contentScore,
      [ENABLING_FLUENCY]: fluencyScore,
      [ENABLING_PRONUNCIATION]: pronunciationScore,
    };

    // Send to analysis/recorder reducer
    store.dispatch(setSpeechText([speechText[0]]));
    store.dispatch(
      setSpeechText2({
        probability,
        averageWordsPerSec,
        speechText: [speechText[0]],
      })
    );
    store.dispatch(setAnalysisScore(data));
    store.dispatch(analyseAlertLoading(false));
    return;
  } else if (question.uid.includes("SRA")) {
    // Read aloud exercise
    var score = 0;

    const realWords = correctAnswer.split(/\s+/).map((w) => cleanString(w));
    const speechTextArray = cleanNumbersFromArray(
      speechText[0].split(/\s+/)
    ).map((w) => cleanString(w));
    const displayArray = speechTextArray.slice(0);

    // Check similar words and remove them
    realWords.map((word) => {
      var cleanWord = cleanNumbersFromString(word);
      if (speechTextArray.includes(cleanWord)) {
        const indexToRemove = speechTextArray.indexOf(cleanWord);
        speechTextArray.splice(indexToRemove, 1, "WORD_REMOVED");
        score += 1;
      }
    });

    // Check errors said
    displayArray.map((word) => {
      var cleanWord = cleanNumbersFromString(word);
      if (!realWords.includes(cleanWord)) {
        score -= 1; // TODO test if it works to deduct points on errors
      }
    });

    // Scores
    var contentScore = cleanScore((score * 5) / realWords.length);
    if (contentScore === 5 && score !== realWords.length) {
      // Prevent score 5 if some words are missing
      contentScore = 4;
    }

    const fluencyScore = getFluencyScore(averageWordsPerSec);
    const pronunciationScore = getPronunciationScore(probability, fluencyScore);

    // Give more weight to content in final score
    var finalScore = contentScore + fluencyScore + pronunciationScore;

    // Prevent too high score if bad contentScore
    if (contentScore <= 1)
      finalScore = Math.round(
        ((contentScore * 5 + fluencyScore + pronunciationScore) / 35) * 15
      );

    const data = {
      [ENABLING_FINAL]: finalScore,
      [ENABLING_CONTENT]: contentScore,
      [ENABLING_FLUENCY]: fluencyScore,
      [ENABLING_PRONUNCIATION]: pronunciationScore,
    };

    // Send to analysis/recorder reducer
    store.dispatch(setSpeechText([speechText[0]]));
    store.dispatch(
      setSpeechText2({
        probability,
        averageWordsPerSec,
        speechText: [speechText[0]],
      })
    );
    store.dispatch(setAnalysisScore(data));
    store.dispatch(analyseAlertLoading(false));
    return;
  } else if (question.uid.includes("SDI") || question.uid.includes("SRL")) {
    // Describe image exercise
    // Retell exercise
    var score = 0;
    var scoreAdjustment = 0;

    const keywords = question.keywords.slice();
    const keywords2 = question.keywords.slice();
    const realWords = correctAnswer.split(/\s+/).map((w) => cleanString(w));
    const speechTextArray = speechText[0]
      .split(/\s+/)
      .map((w) => cleanString(w));

    // Check similar words and remove them
    speechTextArray.map((word) => {
      if (keywords.includes(word)) {
        const indexToRemove = keywords.indexOf(word);
        keywords.splice(indexToRemove, 1, "WORD_REMOVED");
        score += 1;
      }
    });

    // Check similar words and remove them
    realWords.map((word) => {
      if (speechTextArray.includes(word) && !keywords2.includes(word)) {
        const indexToRemove = speechTextArray.indexOf(word);
        speechTextArray.splice(indexToRemove, 1, "WORD_REMOVED");
        scoreAdjustment += 1;
      }
    });

    // Temp scores
    const tempPercentage = Math.min(
      Math.round((score * 100) / keywords.length),
      60
    );
    const tempAdjust = Math.min(scoreAdjustment, 10);

    // 50 words = 20 points
    const numOfWords = speechTextArray.length;
    const tempAdjust2 =
      numOfWords > 30 ? Math.min(Math.round((numOfWords * 20) / 50), 20) : 0;

    // Scores out of 90
    var contentScore = tempPercentage + tempAdjust + tempAdjust2;

    // Content score
    if (contentScore >= 75) {
      contentScore = 5;
    } else if (contentScore >= 55) {
      contentScore = 4;
    } else if (contentScore >= 40) {
      contentScore = 3;
    } else if (contentScore >= 25) {
      contentScore = 2;
    } else if (contentScore >= 10 && contentScore < 25) {
      contentScore = 1;
    } else {
      contentScore = 0;
    }

    const fluencyScore = getFluencyScore(averageWordsPerSec);
    const pronunciationScore = getPronunciationScore(probability, fluencyScore);

    // Give more weight to content in final score
    var finalScore = contentScore + fluencyScore + pronunciationScore;

    // Prevent too high score if bad contentScore
    if (contentScore <= 2)
      finalScore = Math.round(
        ((contentScore * 5 + fluencyScore + pronunciationScore) / 35) * 15
      );

    const data = {
      [ENABLING_FINAL]: finalScore,
      [ENABLING_CONTENT]: contentScore,
      [ENABLING_FLUENCY]: fluencyScore,
      [ENABLING_PRONUNCIATION]: pronunciationScore,
    };

    // Send to analysis/recorder reducer
    store.dispatch(setSpeechText([speechText[0]]));
    store.dispatch(
      setSpeechText2({
        probability,
        averageWordsPerSec,
        speechText: [speechText[0]],
      })
    );
    store.dispatch(setAnalysisScore(data));
    store.dispatch(analyseAlertLoading(false));
    return;
  } else if (question.uid.includes("SIT")) {
    // Respond situation
    var score = 0;
    var scoreAdjustment = 0;

    const keywords = question.keywords.slice();
    const keywords2 = question.keywords.slice();
    const realWords = correctAnswer.split(/\s+/).map((w) => cleanString(w));
    const speechTextArray = speechText[0]
      .split(/\s+/)
      .map((w) => cleanString(w));

    // Check similar words and remove them
    speechTextArray.map((word) => {
      if (keywords.includes(word)) {
        const indexToRemove = keywords.indexOf(word);
        keywords.splice(indexToRemove, 1, "WORD_REMOVED");
        score += 1;
      }
    });

    // Check similar words and remove them
    realWords.map((word) => {
      if (speechTextArray.includes(word) && !keywords2.includes(word)) {
        const indexToRemove = speechTextArray.indexOf(word);
        speechTextArray.splice(indexToRemove, 1, "WORD_REMOVED");
        scoreAdjustment += 1;
      }
    });

    // 40 words = 1 points
    const numOfWords = speechTextArray.length;

    // Temp score keywords out of 60
    const tempPercentage = Math.min(
      Math.round((score * 100) / keywords.length),
      60
    );
    // Temp score keywords out of 10
    const tempAdjust = Math.min(scoreAdjustment, 10);
    // Temp score number of words out of 20
    const tempAdjust2 =
      numOfWords > 40 ? Math.min(Math.round((numOfWords * 20) / 50), 20) : 0;

    // Scores out of 3
    var appropriacyScore = Math.round(
      ((tempPercentage + tempAdjust + tempAdjust2) * 3) / 90
    );

    // Average of appropriacyScore and number of words said from sample answer
    const fluencyScore = getFluencyScore(averageWordsPerSec);

    // Average of appropriacyScore and number of words said from keywords
    const pronunciationScore = getPronunciationScore(probability, fluencyScore);

    // Give more weight to content in final score
    var finalScore = appropriacyScore + fluencyScore + pronunciationScore;

    // Prevent too high score if bad appropriacyScore
    if (appropriacyScore <= 1)
      finalScore = Math.round(
        ((appropriacyScore * 5 + fluencyScore + pronunciationScore) / 25) * 13
      );

    if (isDevMode()) {
      console.log("averageWordsPerSec " + averageWordsPerSec);
      console.log("probability " + probability);
      console.log("tempPercentage " + tempPercentage);
      console.log("tempAdjust " + tempAdjust);
      console.log("tempAdjust2 " + tempAdjust2);
      console.log("appropriacyScore " + appropriacyScore);
      console.log("fluencyScore " + fluencyScore);
      console.log("pronunciationScore " + pronunciationScore);
      console.log("finalScore " + finalScore);
      console.log("score " + score);
      console.log("scoreAdjustment " + scoreAdjustment);
      console.log(
        "ORIGINAL PERCENT " + Math.round((score * 100) / keywords.length)
      );
    }

    const data = {
      [ENABLING_FINAL]: finalScore,
      [ENABLING_FLUENCY]: fluencyScore, // 5
      [ENABLING_APPROPRIACY]: appropriacyScore, // 3
      [ENABLING_PRONUNCIATION]: pronunciationScore, // 5
    };

    // Send to analysis/recorder reducer
    store.dispatch(setSpeechText([speechText[0]]));
    store.dispatch(
      setSpeechText2({
        probability,
        averageWordsPerSec,
        speechText: [speechText[0]],
      })
    );
    store.dispatch(setAnalysisScore(data));
    store.dispatch(analyseAlertLoading(false));
    return;
  }
};

export {
  getScoreForSpeaking,
  getScoreForWritingEmail,
  getScoreForWritingEssay,
  getScoreForWritingSummarize,
  getScoreForListeningSummarize,
  getScoreForWritingSummarizeCore,
  getScoreForListeningSummarizeCore,
};
