// Route
export const ROUTE_HOME = "/";
export const ROUTE_BLOG = "/blog";
export const ROUTE_PERK = "/perk";
export const ROUTE_LOGIN = "/login";
export const ROUTE_PROMO = "/promo";
export const ROUTE_USER = "/profile";
export const ROUTE_ADMIN = "/admin";
export const ROUTE_REWARD = "/reward";
export const ROUTE_VERIFY = "/verify";
export const ROUTE_TESTIMONIALS = "/review";
export const ROUTE_SIGNUP = "/register";
export const ROUTE_PRICING = "/pricing";
export const ROUTE_CHECKOUT = "/checkout";
export const ROUTE_AFFILIATE_CHECK = "/u";
export const ROUTE_PRACTICE = "/practice";
export const ROUTE_EXERCISE = "/exercise";
export const ROUTE_EXERCISE_MOCK = "/mock-test/exercise";
export const ROUTE_EXERCISE_MOCK_DESKTOP = "/mock-test/desktop";
export const ROUTE_NOT_FOUND = "/not-found";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_MOCK_TEST = "/mock-test";
export const ROUTE_SCORECARD = "/scorecard";
export const ROUTE_TEMPLATES = "/templates";
export const ROUTE_RESOURCES = "/resources";
export const ROUTE_STATISTICS = "/statistics";
export const ROUTE_CONTACT_US = "/contact-us";
export const ROUTE_REGULATIONS = "/regulations";
export const ROUTE_PASSWORD = "/reset-password";
export const ROUTE_DESKTOP_ONLY = "/desktop-only";
export const ROUTE_SET_PASSWORD = "/set-password";
export const ROUTE_VOCABULARY = "/resources/vocabulary";
export const ROUTE_MOCK_TEST_RESULT = "/mock-test/results";
export const ROUTE_MOCK_TEST_SCORECARD = "/mock-test/scorecard";
export const ROUTE_MOBILE_APP = "/download-pte-mobile-app";
export const ROUTE_MOBILE_APP_IOS = "/download-pte-mobile-app/ios";
export const ROUTE_MOBILE_APP_ANDROID = "/download-pte-mobile-app/android";
export const ROUTE_PRACTICE_WRITING = "/practice?section=Writing&exercise=All";
export const ROUTE_PRACTICE_READING = "/practice?section=Reading&exercise=All";
export const ROUTE_PRACTICE_SPEAKING =
  "/practice?section=Speaking&exercise=All";
export const ROUTE_PRACTICE_LISTENING =
  "/practice?section=Listening&exercise=All";
export const ROUTE_PRACTICE_PREDICTION =
  "/practice?section=Reading&exercise=All&prediction=true";

export const ROUTE_HELP = "/help";
export const ROUTE_SURVEY = "/survey";
export const ROUTE_TERMS = "/regulations/terms-of-use";
export const ROUTE_WHY_US = "/why-us";
export const ROUTE_THANK_YOU = "/thank-you";
export const ROUTE_THANK_YOU_MOBILE = "/thank-you-mobile";
export const ROUTE_PRIVACY = "/regulations/privacy-policy";
export const ROUTE_ABOUT = "/about-us";
export const ROUTE_LANDING = "/academic";
export const ROUTE_FREE_SHORT_ANSWER =
  "/pte-short-answer-tips-and-free-questions";
export const ROUTE_FREE_WRITE_DICTATION =
  "/write-from-dictation-tips-and-free-questions";
export const ROUTE_FREE_READ_ALOUD = "/read-aloud-tips-and-free-questions";
export const ROUTE_FREE_REPEAT_SENTENCE =
  "/pte-repeat-sentence-and-free-questions";
export const ROUTE_JOIN_SUBSCRIBERS_LIST = "/get-latest-tips";

// Auth
export const SET_USER = "SET_USER";

// Snackbar notifs
export const GET_SNACKBAR = "GET_SNACKBAR";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";
export const SNACK_WRONG_PDF = "SNACK_WRONG_PDF";
export const SNACK_DEV_ERROR = "SNACK_DEV_ERROR";
export const SNACK_EMAIL_SENT = "SNACK_EMAIL_SENT";
export const SNACK_RESET_LINK = "SNACK_RESET_LINK";
export const SNACK_TEXT_COPIED = "SNACK_TEXT_COPIED";
export const SNACK_WRONG_IMAGE = "SNACK_WRONG_IMAGE";
export const SNACK_ERROR_VERIFY = "SNACK_ERROR_VERIFY";
export const SNACK_REWARD_CLAIM = "SNACK_REWARD_CLAIM";
export const SNACK_WRONG_FORMAT = "SNACK_WRONG_FORMAT";
export const SNACK_UPDATED_USER = "SNACK_UPDATED_USER";
export const SNACK_MULTI_ACCOUNT = "SNACK_MULTI_ACCOUNT";
export const SNACK_NOT_SCORECARD = "SNACK_NOT_SCORECARD";
export const SNACK_PROMO_EXPIRED = "SNACK_PROMO_EXPIRED";
export const SNACK_PROMO_ALREADY = "SNACK_PROMO_ALREADY";
export const SNACK_SURVEY_EXPIRED = "SNACK_SURVEY_EXPIRED";
export const SNACK_SURVEY_ALREADY = "SNACK_SURVEY_ALREADY";
export const SNACK_CRITICAL_ERROR = "SNACK_CRITICAL_ERROR";
export const SNACK_PASSWORD_RESET = "SNACK_PASSWORD_RESET";
export const SNACK_EMAIL_NOT_SENT = "SNACK_EMAIL_NOT_SENT";
export const SNACK_EMAIL_VERIFIED = "SNACK_EMAIL_VERIFIED";
export const SNACK_USER_NOT_FOUND = "SNACK_USER_NOT_FOUND";
export const SNACK_ACCOUNT_DELETED = "SNACK_ACCOUNT_DELETED";
export const SNACK_EMAIL_LIST_ADDED = "SNACK_EMAIL_LIST_ADDED";
export const SNACK_LEFT_TESTIMONIAL = "SNACK_LEFT_TESTIMONIAL";
export const SNACK_PAYMENT_CANCELLED = "SNACK_PAYMENT_CANCELLED";
export const SNACK_EMAIL_VERIFY_SENT = "SNACK_EMAIL_VERIFY_SENT";
export const SNACK_EMAIL_NOT_VERIFIED = "SNACK_EMAIL_NOT_VERIFIED";
export const SNACK_USER_TOKEN_EXPIRED = "SNACK_USER_TOKEN_EXPIRED";

// Surveys
export const SURVEY_TEXTBOX = "SURVEY_TEXTBOX";
export const SURVEY_SUGGESTION = "SURVEY_SUGGESTION";

// Bell notifs
export const NOTIF_NEW_REFERRAL = "NOTIF_NEW_REFERRAL";
export const NOTIF_DID_SUBSCRIBED = "NOTIF_DID_SUBSCRIBED";
export const NOTIF_BOUGHT_ADD_ON = "NOTIF_BOUGHT_ADD_ON";
export const NOTIF_SPEAKING_1_4 = "NOTIF_SPEAKING_1_4";
export const NOTIF_SPEAKING_1_2 = "NOTIF_SPEAKING_1_2";
export const NOTIF_SPEAKING_3_4 = "NOTIF_SPEAKING_3_4";
export const NOTIF_SPEAKING_4_4 = "NOTIF_SPEAKING_4_4";
export const NOTIF_COMPLETE_SURVEY = "NOTIF_COMPLETE_SURVEY";

export const NAVIGATE_TO = "NAVIGATE_TO";
export const CLEAR_NAVIGATE = "CLEAR_NAVIGATE";

// Url Params
export const PARAMS_ID = "id";
export const PARAMS_UID = "uid";
export const PARAMS_TOKEN = "token";
export const PARAMS_EMAIL = "email";
export const PARAMS_PASSWORD = "password";
export const PARAMS_SUBMIT = "submit";

// Timer is up
export const TIMER_SUBMIT = "TIMER_SUBMIT";
export const TIMER_SECTION = "TIMER_SECTION";
export const TIMER_MOCK_TEST = "TIMER_MOCK_TEST";
export const TIMER_BREAK_READING = "TIMER_BREAK_READING";
export const TIMER_BREAK_LISTENING = "TIMER_BREAK_LISTENING";

// Access
export const STUDENT = "student";

// Errors
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Payment
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PROMOTIONS = "GET_PROMOTIONS";

// Admin
export const GET_ADMIN_SETTINGS = "GET_ADMIN_SETTINGS";

export const QUICK_MODE = "QUICK_MODE";
export const SUPER_QUICK_MODE = "SUPER_QUICK_MODE";

// Exercises
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_PREDICTED_QUESTIONS = "GET_PREDICTED_QUESTIONS";
export const GET_ANALYSIS = "GET_ANALYSIS";
export const GET_PRACTICE_QUESTIONS = "GET_PRACTICE_QUESTIONS";
export const PRACTICE_DISPLAY = "PRACTICE_DISPLAY";
export const ESSAY_ANALYSED = "ESSAY_ANALYSED";
export const GET_SYNONYMS = "GET_SYNONYMS";
export const CLEAR_CURRENT_QUESTION = "CLEAR_CURRENT_QUESTION";
export const SET_ANALYSIS_SCORE = "SET_ANALYSIS_SCORE";
export const ANALYSIS_LOADING = "ANALYSIS_LOADING";
export const CLEAR_ANALYSIS_SCORE = "CLEAR_ANALYSIS_SCORE";
export const SET_UPSELL = "SET_UPSELL";
export const CLEAR_UPSELL = "CLEAR_UPSELL";
export const SET_DATE_NOW = "SET_DATE_NOW";

export const SET_PRACTICE = "SET_PRACTICE";
export const SET_DID_SUBMIT = "SET_DID_SUBMIT";
export const CLEAR_PRACTICE = "CLEAR_PRACTICE";
export const SET_PRACTICE_SCORE = "SET_PRACTICE_SCORE";
export const SET_PRACTICE_SCORE_DATA = "SET_PRACTICE_SCORE_DATA";
export const SET_MOCK_TEST_IS_UP = "SET_MOCK_TEST_IS_UP";
export const CLEAR_MOCK_TEST_IS_UP = "CLEAR_MOCK_TEST_IS_UP";
export const CLEAR_FOR_NEXT_QUESTION = "CLEAR_FOR_NEXT_QUESTION";
export const SET_SHOW_ANSWER = "SET_SHOW_ANSWER";
export const SET_MOCK_TEST_MODE = "SET_MOCK_TEST_MODE";
export const SET_REVIEW_MODE = "SET_REVIEW_MODE";
export const SET_PRACTICE_MOCK = "SET_PRACTICE_MOCK";
export const SET_MOCK_TEST_MODE_ONLY = "SET_MOCK_TEST_MODE_ONLY";

// Loading global
export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";
export const START_RETRIEVING_USER = "START_RETRIEVING_USER";
export const FINISH_RETRIEVING_USER = "FINISH_RETRIEVING_USER";

// Mock test
export const GET_MOCK_TESTS = "GET_MOCK_TESTS";
export const MOCK_TEST_FREE_1 = "mock_test_new_1";
export const MOCK_TEST_CORE_FREE_1 = "mock_test_core_2024_1";
export const MOCK_TEST_EXAMPLE = "mock_test_new_example";

// Blog
export const GET_BLOGS = "GET_BLOGS";

// Perks
export const GET_PERKS = "GET_PERKS";

// Statistics
export const GET_STATISTICS = "GET_STATISTICS";

// Recorder
export const PLAYER_DID_FINISH = "PLAYER_DID_FINISH";
export const SET_CLICKED_NEXT = "SET_CLICKED_NEXT";
export const CLEAR_RECORDER = "CLEAR_RECORDER";
export const RECORDER_TOGGLE_RECORDING = "RECORDER_TOGGLE_RECORDING";
export const RECORDER_IS_RECORDING = "RECORDER_IS_RECORDING";
export const RECORDER_REVIEW_MODE = "RECORDER_REVIEW_MODE";
export const RECORDER_DID_FINISH = "RECORDER_DID_FINISH";
export const RECORDER_END = "RECORDER_END";
export const RECORDER_TIME_WAITING = "RECORDER_TIME_WAITING";
export const RECORDER_TIME_ANSWERING = "RECORDER_TIME_ANSWERING";
export const RECORDER_SILENCE_DETECTED = "RECORDER_SILENCE_DETECTED";
export const RECORDER_ACCESS_DENIED = "RECORDER_ACCESS_DENIED";
export const SET_SPEECH_TEXT = "SET_SPEECH_TEXT";
export const SET_SPEECH_TEXT_2 = "SET_SPEECH_TEXT_2";
export const FORCE_SHOW_ANSWER = "FORCE_SHOW_ANSWER";
export const SET_BLOB_URL = "SET_BLOB_URL";
export const SET_BLOB_URL_OVERVIEW = "SET_BLOB_URL_OVERVIEW";

// Exam types
export const EXAM_CORE = "Core";
export const EXAM_UKVI = "UKVI";
export const EXAM_UK_A1 = "Home A1";
export const EXAM_UK_A2 = "Home A2";
export const EXAM_UK_B1 = "Home B1";
export const EXAM_ACADEMIC = "Academic";

// Categories
export const CATEGORY_READING = "PTE Reading";
export const CATEGORY_LISTENING = "PTE Listening";
export const CATEGORY_SPEAKING = "PTE Speaking";
export const CATEGORY_WRITING = "PTE Writing";
export const CATEGORY_GENERAL = "PTE General";

// Sections
export const FULL = "Full";
export const READING = "Reading";
export const LISTENING = "Listening";
export const SPEAKING = "Speaking";
export const WRITING = "Writing";
export const OVERALL = "Overall";
export const PERSONAL_INTRO = "Personal Introduction";

// Exercises
export const SPEAKING_SHORT_ANSWER = "Answer Short Question";
export const SPEAKING_REPEAT_SENTENCE = "Repeat Sentence";
export const SPEAKING_READ_ALOUD = "Read Aloud";
export const SPEAKING_DESCRIBE_IMAGE = "Describe Image";
export const SPEAKING_RETELL_LECTURE = "Re-tell Lecture";
export const SPEAKING_RESPOND_SITUATION = "Respond to a Situation";

export const READING_SINGLE = "Single Answer";
export const READING_MULTIPLE = "Multiple Answers";
export const READING_REORDER = "Re-order Paragraphs";
export const READING_FILL_BLANK = "Fill in Blanks";
export const READING_WRITING_FILL_BLANK = "R/W Fill in Blanks";

// Steps done
export const STEPS_DID_ONBOARDING = "did_onboarding";
export const STEPS_DID_ONBOARDING_PRACTICE = "did_onboarding_pratice";
export const STEPS_VIEW_REWARD = "did_view_reward";
export const STEPS_ACCESS_BLOCKED = "access_blocked";
export const STEPS_WANT_BG_NOISE = "want_bg_noise";
export const STEPS_SEE_DETAILS = "did_view_score_details";
export const STEPS_SEE_SKILL = "did_view_official_skill";
export const STEPS_AUTO_NIGHT = "should_auto_night_mode";
export const STEPS_EMAIL_GENERAL = "can_send_email_general";
export const STEPS_EMAIL_THIRD_PARTY = "can_send_email_third_party";
export const STEPS_DID_CLAIM_REWARD = "did_claim_reward";
export const STEPS_SHOULD_HIDE_TASK = "should_hide_task";
export const STEPS_SHOULD_TESTIMONIAL = "should_leave_testimonial";
export const STEPS_AUTO_SILENCE_OFF = "should_auto_silence_off";
export const STEPS_BYPASS_WHISPER_API = "should_bypass_whisper_api";
export const STEPS_LEFT_TESTIMONIAL = "did_leave_testimonial";
export const STEPS_CAN_TAKE_MOCK_TEST_1 = "can_take_mock_test_1";
export const STEPS_DID_VERIFY_EMAIL = "did_verify_email";
export const STEPS_DID_SEND_VERIFY_EMAIL = "did_send_verify_email";
export const STEPS_SHOULD_SET_PASSWORD = "should_set_password";
export const STEPS_WECHAT_WEBHOOK = "did_pay_with_wechat";
export const STEPS_ADMIN_NOTIFY_SUSPICIOUS_EMAIL =
  "should_notify_suspicious_email";
export const STEPS_AUTO_PLAYBACK_OFF = "should_playback_off";

// Localstorage
export const ROWS_ORDER = "rowsOrder";
export const ROWS_PROPERTY = "rowsProperty";
export const ROWS_PER_PAGE = "rowsPerPage";
export const IS_NIGHT_MODE = "isNightMode";
export const EXAM_SELECTED = "examSelected";
export const EXERCISE_SELECTED = "exerciseSelected";
export const SECTION_SELECTED = "sectionSelected";
export const UNANSWERED_ONLY = "unansweredOnly";
export const JWT_TOKEN = "jwtToken";
export const REFERRAL_ID = "referralId";
export const COUPON_ID = "couponId";
export const VOLUME = "volume";
export const LOCATION = "location";
export const PREDICTION_MODE = "predictionMode";
export const FAVORITE_MODE = "favoriteMode";
export const FREE_SPEAKING_CREDITS = "freeSpeakingCredits";
export const FREE_SPEAKING_CREDITS_NUMBER = 10;
export const HAS_CPC_OFFER = "cpcOffer";
export const HAS_REGISTERED_ONCE = "hasRegisteredOnce";
export const HAS_CLOSED_BANNER = "hasClosedBanner";
export const HAS_CLOSED_WARNING_2 = "hasClosedWarning2";
export const HAS_SHOWN_OVERVIEW = "hasShownOverview";
export const UPSELL_FULL_MOCK_CREDITS = "upsellFullMockCredits";
export const CHROME_WARNING = "hasShownChromeWarning";

// Enbling writing skills
export const ENABLING_FORM = "Form";
export const ENABLING_EMAIL_CONV = "Email conventions";
export const ENABLING_ORGANIZATION = "Organization";
export const ENABLING_VOC = "Vocabulary";
export const ENABLING_GRAMMAR = "Grammar";
export const ENABLING_SPELLING = "Spelling";
export const ENABLING_CONTENT = "Content";
export const ENABLING_APPROPRIACY = "Appropriacy";
export const ENABLING_PRONUNCIATION = "Pronunciation";
export const ENABLING_FLUENCY = "Oral fluency";
export const ENABLING_DSC = "Development, structure and coherence";
export const ENABLING_GLR = "General linguistic range";
export const ENABLING_FINAL = "Final";
export const ENABLING_NUM_SPELL_MISTAKE = "num_spell_mistake";
export const ENABLING_NUM_GRAMMAR_MISTAKE = "num_grammar_mistake";
export const ENABLING_DISCOURSE = "Written Discourse";

///////////////
// FRAGMENTS //
///////////////

export const LISTING = "LISTING";
export const STATISTICS = "STATISTICS";
export const SETTINGS = "SETTINGS";
export const CONTACT_US = "CONTACT_US";

///////////////
//  BROWSERS //
///////////////

export const CHROME = "Chrome";
export const SAFARI = "Safari";
export const FIREFOX = "Firefox";
export const DESKTOP = "desktop";

////////////////
//  PACKAGES  //
////////////////
export const SILVER = "package_silver";
export const SILVER_DISCOUNT = "package_silver_discount";
export const BRONZE = "package_bronze";
export const BRONZE_DISCOUNT = "package_bronze_discount";
export const BRONZE_DISCOUNT_SECT = "package_bronze_sect";
export const ULTIMATE = "package_ultimate";
export const ULTIMATE_DISCOUNT = "package_ultimate_discount";

export const SILVER_UPGRADE_FROM_BRONZE = "package_silver_upgrade_from_bronze";
export const ULTIMATE_UPGRADE_FROM_BRONZE =
  "package_ultimate_upgrade_from_bronze";
export const ULTIMATE_UPGRADE_FROM_SILVER =
  "package_ultimate_upgrade_from_silver";

export const EXTENSION = "package_extend";
export const SPEAKING_CREDITS = "package_speaking_credits";
export const COMBO = "package_combo";

export const FULL_MOCK_PACKAGE = "package_full_mock";
export const FULL_MOCK_PACKAGE_2 = "package_full_mock_2";

export const SECT_MOCK_PACKAGE = "package_sect_mock";
export const SECT_MOCK_PACKAGE_2 = "package_sect_mock_2";

export const FULL_MOCK_UPSELL = "full_mock_test_upsell";
export const SECTIONAL_MOCK_UPSELL = "mock_test_sectional_upsell";

////////////////
//  PAYMENTS  //
////////////////
export const VISA = "card";
export const PAYPAL = "paypal";
export const WECHAT = "wechat_pay";
export const ALIPAY = "alipay";
export const APPLEPAY = "apple pay";
export const GOOGLEPAY = "google pay";
export const BANCONTACT = "bancontact";

////////////////
// HOME COMPONENTS LAYOUT KEYS
////////////////
export const HOME_HERO_SECTION = "HOME_HERO_SECTION";
export const HOME_GRID_SECTION = "HOME_GRID_SECTION";
export const HOME_SLIDER_SECTION = "HOME_SLIDER_SECTION";
export const HOME_TESTIMONIAL_SECTION = "HOME_TESTIMONIAL_SECTION";
export const HOME_FAQ_SECTION = "HOME_FAQ_SECTION";
export const HOME_BUY_SECTION = "HOME_BUY_SECTION";
export const HOME_LAST_SECTION = "HOME_LAST_SECTION";
export const HOME_TWO_COL_IMAGE_SECTION = "HOME_TWO_COL_IMAGE_SECTION";
export const HOME_THREE_COL_SECTION = "HOME_THREE_COL_SECTION";
export const HOME_THREE_COL_IMAGE_SECTION = "HOME_THREE_COL_IMAGE_SECTION";
export const HOME_VIEWER_SECTION = "HOME_VIEWER_SECTION";
export const HOME_BLOG_SECTION = "HOME_BLOG_SECTION";
export const HOME_BANNER_SECTION = "HOME_BANNER_SECTION";
export const HOME_VIDEO_TESTIMONIAL_SECTION = "HOME_VIDEO_TESTIMONIAL_SECTION";
export const HOME_TWO_VIDEO_TESTIMONIAL_SECTION =
  "HOME_TWO_VIDEO_TESTIMONIAL_SECTION";
export const HOME_TEMPLATES_SECTION = "HOME_TEMPLATES_SECTION";

// Review
export const SET_USERS_TESTIMONIAL = "SET_USERS_TESTIMONIAL";

// STRIPE
export const STRIPE_PK_KEY_TEST = "pk_test_SXu1HlLJuVMI8HU6GhpQiNjx";
export const STRIPE_PK_KEY_LIVE =
  "pk_live_51CLNfwICp80PtA8444SWQmFlOxGmwRAnJO0JdRtbYKCOuaZ6bbgVJIsdQlLhhhXCymwTMl9MWpJZo8aAnsM8g4VS00VU8nDPfh";

// PAYPAL
export const PAYPAL_ID_TEST =
  "AfcX4zVhM6ZdDTsYqSwHtbLJ9mxdKMl3QGbPVIW8RxDyyXAJb-O-fjyS_aPRLCjwEfAj-wWLOsggkmHN";
export const PAYPAL_ID_LIVE =
  "AVJBxMqES24Q6HphlzLul-iKWS_COkMdAJNYPZW3MMnVHQbTVVDDLWcbiB6FNfLPud7_O_JO22kUQ-fX";

// CAPTCHA
export const RECAPTCHA_SITE_KEY = "6LfKLPYhAAAAANykxYGDOnDKFluwv8K_YYZeftKL";
