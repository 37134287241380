import {
  SET_BLOB_URL,
  RECORDER_END,
  CLEAR_RECORDER,
  SET_SPEECH_TEXT,
  SET_CLICKED_NEXT,
  PLAYER_DID_FINISH,
  FORCE_SHOW_ANSWER,
  SET_SPEECH_TEXT_2,
  RECORDER_DID_FINISH,
  RECORDER_REVIEW_MODE,
  RECORDER_IS_RECORDING,
  RECORDER_TIME_WAITING,
  SET_BLOB_URL_OVERVIEW,
  RECORDER_ACCESS_DENIED,
  RECORDER_TIME_ANSWERING,
  RECORDER_SILENCE_DETECTED,
  RECORDER_TOGGLE_RECORDING,
} from "../helpers/types";

const initialState = {
  blob: null,
  speechText: [],
  speechText2: {},
  timeLeftWaiting: 0,
  clickedNext: false,
  isRecording: false,
  accessDenied: false,
  timeLeftAnswering: 0,
  startRecorder: false,
  recorderDidEnd: false,
  forceShowAnswer: false,
  silenceDetected: false,
  playerDidFinish: false,
  timerShouldStart: false,
  didFinishRecording: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECORDER_ACCESS_DENIED:
      return {
        ...state,
        accessDenied: action.payload,
      };
    case PLAYER_DID_FINISH:
      return {
        ...state,
        playerDidFinish: true,
        startRecorder: false,
        timerShouldStart: true,
      };
    case RECORDER_TIME_WAITING:
      return {
        ...state,
        timeLeftWaiting: action.payload,
      };
    case RECORDER_TIME_ANSWERING:
      return {
        ...state,
        timeLeftAnswering: action.payload,
      };
    case RECORDER_TOGGLE_RECORDING:
      return {
        ...state,
        startRecorder: action.payload,
      };
    case RECORDER_END:
      return {
        ...state,
        recorderDidEnd: action.payload,
      };
    case RECORDER_SILENCE_DETECTED:
      return {
        ...state,
        silenceDetected: true,
      };
    case RECORDER_IS_RECORDING:
      return {
        ...state,
        isRecording: action.payload,
        silenceDetected: action.payload ? false : state.silenceDetected,
        didFinishRecording: action.payload ? false : state.didFinishRecording,
        // Toggle off only if starts recording
      };
    case RECORDER_DID_FINISH:
      return {
        ...state,
        didFinishRecording: action.payload,
        isRecording: false,
      };
    case SET_SPEECH_TEXT:
      return {
        ...state,
        speechText: action.payload,
      };
    case SET_SPEECH_TEXT_2:
      return {
        ...state,
        speechText2: action.payload,
      };
    case SET_BLOB_URL:
      if (state.timerShouldStart) {
        return {
          ...state,
          blob: action.payload,
          didFinishRecording: true,
          startRecorder: false,
        };
      } else {
        return { ...state };
      }
    case SET_BLOB_URL_OVERVIEW:
      return {
        ...state,
        blob: action.payload,
        didFinishRecording: true,
        startRecorder: false,
      };
    case FORCE_SHOW_ANSWER:
      return {
        ...state,
        forceShowAnswer: action.payload,
      };
    case RECORDER_REVIEW_MODE:
      return {
        ...state,
        playerDidFinish: true,
        startRecorder: false,
        timerShouldStart: true,
        didFinishRecording: true,
        isRecording: false,
      };
    case SET_CLICKED_NEXT:
      return {
        ...state,
        clickedNext: action.payload,
      };
    case CLEAR_RECORDER:
      return {
        ...state,
        blob: null,
        speechText: [],
        speechText2: {},
        clickedNext: false,
        isRecording: false,
        accessDenied: false,
        recorderDidEnd: false,
        startRecorder: false,
        forceShowAnswer: false,
        silenceDetected: false,
        playerDidFinish: false,
        timerShouldStart: false,
        didFinishRecording: false,
      };
    default:
      return state;
  }
}
