import {
  BRONZE,
  SILVER,
  ULTIMATE,
  EXAM_CORE,
  EXAM_ACADEMIC,
  EXAM_SELECTED,
  IS_NIGHT_MODE,
  SILVER_DISCOUNT,
  BRONZE_DISCOUNT,
  STEPS_AUTO_NIGHT,
  MOCK_TEST_FREE_1,
  ULTIMATE_DISCOUNT,
  BRONZE_DISCOUNT_SECT,
  MOCK_TEST_CORE_FREE_1,
  SILVER_UPGRADE_FROM_BRONZE,
  ULTIMATE_UPGRADE_FROM_SILVER,
  ULTIMATE_UPGRADE_FROM_BRONZE,
} from "./types";
import isEmpty from "./isEmpty";
import { getExerciseSettings } from "./getExerciseData";

const moment = require("moment");

// Check if user is authenticated
const isAuthenticated = (user) => {
  return !isEmpty(user);
};

// Check if user is an admin
const isAdmin = (user) => {
  return !isEmpty(user) && user.platformAccess === "admin";
};

// Check if user is subscribed
const isPaidStudent = ({ user, settings }) => {
  return user.paidExpiryTime > settings?.dateNow;
};

// Check if user has mobile device linked
const isMobileUser = (user) => {
  return !isEmpty(user) && !isEmpty(user?.redeemedMobileApp);
};

// Check if user is registered in last 30 minutes
const isNewUser = (user) => {
  const today = new Date();
  const mins30 = today.setMinutes(today.getMinutes() - 30);
  return isAuthenticated(user) && moment(user.dateCreated).isAfter(mins30);
};

// Check if user is referred
const isReferredUser = (user) => {
  return isAuthenticated(user) && !isEmpty(user.referralId);
};

// Check if user is in practice mode
const isInPracticeMode = (practice) => {
  return !isEmpty(practice.uids);
};

// Check if student from previous version and still is paid
const isUltimateStudent = ({ user, settings }) => {
  return (
    !isEmpty(user) &&
    (user?.currentPlan === ULTIMATE ||
      user?.currentPlan === ULTIMATE_DISCOUNT ||
      user?.currentPlan === ULTIMATE_UPGRADE_FROM_BRONZE ||
      user?.currentPlan === ULTIMATE_UPGRADE_FROM_SILVER) &&
    user.paidExpiryTime > settings?.dateNow
  );
};

// Check if student has Bronze pack
const isBronzeStudent = (user) => {
  return (
    !isEmpty(user) &&
    (user?.currentPlan === BRONZE ||
      user?.currentPlan === BRONZE_DISCOUNT ||
      user?.currentPlan === BRONZE_DISCOUNT_SECT)
  );
};

// Check if student has Silver pack
const isSilverStudent = (user) => {
  return (
    !isEmpty(user) &&
    (user?.currentPlan === SILVER ||
      user?.currentPlan === SILVER_DISCOUNT ||
      user?.currentPlan === SILVER_UPGRADE_FROM_BRONZE)
  );
};

// Check if student from previous version and still is paid
const isPremiumOrUltimateStudent = ({ user, settings }) => {
  return (
    !isEmpty(user) &&
    (user.purchaseHistory.includes(SILVER) ||
      user.purchaseHistory.includes(SILVER_DISCOUNT) ||
      user.purchaseHistory.includes(SILVER_UPGRADE_FROM_BRONZE) ||
      user.purchaseHistory.includes(ULTIMATE) ||
      user.purchaseHistory.includes(ULTIMATE_DISCOUNT) ||
      user.purchaseHistory.includes(ULTIMATE_UPGRADE_FROM_BRONZE) ||
      user.purchaseHistory.includes(ULTIMATE_UPGRADE_FROM_SILVER)) &&
    user.paidExpiryTime > settings?.dateNow
  );
};

// Return night mode from user or local storage if not login
const isNightMode = (user) => {
  if (!isEmpty(user)) {
    if (doesUserHave(user, STEPS_AUTO_NIGHT)) {
      // Automatic night mode between 8pm and 8am
      const isNightTime =
        moment().format("HH") < (user.nightTimeEnd || 8) ||
        moment().format("HH") >= (user.nightTimeStart || 20);
      return isNightTime;
    } else {
      return user.isNightMode;
    }
  } else {
    return Boolean(localStorage[IS_NIGHT_MODE] === "true");
  }
};

// Return exam type from user or local storage if not login
const getExamType = (user) => {
  if (!isEmpty(user)) {
    return user?.examType || EXAM_ACADEMIC;
  } else {
    return localStorage[EXAM_SELECTED] || EXAM_ACADEMIC;
  }
};

// Return exam free id
const getFreeMockTestId = (user) => {
  if (!isEmpty(user) && user?.examType === EXAM_CORE) {
    return MOCK_TEST_CORE_FREE_1;
  } else {
    return MOCK_TEST_FREE_1;
  }
};

// Check if user has property in stepsDone or local storage if not login
const doesUserHave = (user, step) => {
  if (!isEmpty(user)) {
    return user.stepsDone.includes(step);
  } else {
    return Boolean(localStorage[step] === "true");
  }
};

// Check if user can submit writing
const canSubmitWriting = (user, uid) => {
  if (
    !["WWE", "WWS", "WSC", "LST", "LCT"].includes(getExerciseSettings(uid).uid)
  )
    return true;

  return !isEmpty(user) && user.writingCredits > 0;
};

// Check if user can open the question
const hasAccess = ({ uid, user, settings }) => {
  // User should be logged in and paid user / otherwise free question only
  return (
    allowedQuestions().includes(uid) ||
    (isAuthenticated(user) && user.paidExpiryTime > settings?.dateNow)
  );
};

function allowedQuestions() {
  return [
    "SRS951287436925",
    "SRS128573964015",
    "SRS680139257246",
    "SRS659812046537",
    "SRS702935410286",

    "SRA1624577833056",
    "SRA1624577833045",
    "SRA1624577833041",
    "SRA1624577833040",
    "SRA1624577833019",

    "SSQ617894230112",
    "SSQ924867135291",
    "SSQ678920153829",
    "SSQ275496103827",
    "SSQ1581026863046",

    "SDI1624704309001",
    "SDI1624704309003",
    "SDI1687260800002",
    "SDI1624704309008",
    "SDI1687260800007",

    "SRL1624765691002",
    "SRL1624765691001",
    "SRL1624765691000",

    "SIT1708575943000",
    "SIT1708575943001",
    "SIT1708575943002",

    "RSA1000",
    "RSA35",
    "RSA46",
    "RSA1001",
    "RSA1004",

    "RMA1000",
    "RMA1001",
    "RMA2",
    "RMA3",
    "RMA4",

    "RRP1604375707874",
    "RRP1604375707870",
    "RRP1604375707867",
    "RRP1604375707859",
    "RRP1562212520266",

    "RFG1688947178080",
    "RFG1688947178082",
    "RFG1688947178086",
    "RFG1688947178088",
    "RFG1688947178098",

    "RFB1688683495086",
    "RFB1688683495087",
    "RFB1688683495084",
    "RFB1688683495082",
    "RFB1688683495099",

    "LSA1687000104012",
    "LSA1687000103007",
    "LSA1687000104013",
    "LSA1687000104014",
    "LSA1687000104018",

    "LMA101",
    "LMA102",
    "LMA103",
    "LMA104",
    "LMA105",

    "LWD1624665374008",
    "LWD1624665374012",
    "LWD1624665374014",
    "LWD1580808427230",
    "LWD1624665374028",

    "LHS1",
    "LHS2",
    "LHS3",
    "LHS5",
    "LHS6",

    "LIW148",
    "LIW102",
    "LIW103",
    "LIW105",
    "LIW104",

    "LWB1688097389034",
    "LWB1688097389038",
    "LWB1688097389036",
    "LWB1688097389037",
    "LWB1688097389049",

    "LST1687561575051",
    "LST1687561575052",
    "LST1687561575050",
    "LST1687561575054",
    "LST1687561575062",

    "LCT1687561575051",
    "LCT1687561575052",
    "LCT1687561575050",
    "LCT1687561575054",
    "LCT1687561575062",

    "LMW1686888796000",
    "LMW1686888796002",
    "LMW1686888796004",
    "LMW1686888796007",
    "LMW1686888796009",

    "WWE1581483946010",
    "WWE1581483946011",

    "WWS1581644195001",
    "WWS1581644195002",

    "WSC1581644195001",
    "WSC1581644195002",

    "WWM1708392197000",
    "WWM1708392197001",
  ];
}

export {
  isAdmin,
  isNewUser,
  hasAccess,
  getExamType,
  isNightMode,
  doesUserHave,
  isMobileUser,
  isPaidStudent,
  isReferredUser,
  isAuthenticated,
  isBronzeStudent,
  isSilverStudent,
  isInPracticeMode,
  canSubmitWriting,
  allowedQuestions,
  isUltimateStudent,
  getFreeMockTestId,
  isPremiumOrUltimateStudent,
};
