import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import AudioPlayer from "./components/AudioPlayer";
import TextFieldGroup from "../common/TextFieldGroup";
import AlertSkillAnalysisWriting from "../alerts/AlertSkillAnalysisWriting";
import AlertSkillAnalysisPreviousWriting from "../alerts/AlertSkillAnalysisPreviousWriting";

import {
  isPaidStudent,
  isAuthenticated,
  canSubmitWriting,
} from "../../helpers/userCheck";
import {
  setAnalysis,
  getUserWritings,
  saveUserWriting,
  analyseWritingText,
} from "../../actions/analysisActions";
import { clearErrors } from "../../actions/errorActions";
import { setPracticeScore } from "../../actions/questionActions";

import isEmpty from "../../helpers/isEmpty";
import { stableSort, getSorting } from "../../helpers/tableListHelper";
import { getScoreForListeningSummarizeCore } from "../../helpers/getScoreForAnalysis";

// Listening summarize spoken text exercise (CORE)
function ExerciseListeningSpokenTextCore({ question, didSubmit, ...props }) {
  const classes = useStyles();

  const { track, sampleAnswer, transcript, uid } = question;
  const { analysis, score } = props.analysis;
  const { user, errors } = props;

  const [selectedAnswer, setSelectedAnswer] = useState(
    ""
    // 'It is believed that touch can communicate a wide range of specific emotions. Touch has been ignored by researchers in the past, and has not been focused on until recently.\n\nA person describes an experiment carried out into touch and emotion to prove that touch can communicate a range of specific feelings.'
  );
  const [htmlJsx, setHtmlJsx] = useState("");
  const [previousWritings, setPreviousWritings] = useState([]);

  // Check if user can submit writing
  const isWritingDisabled = !canSubmitWriting(user, uid);

  // Get previous submitted writings
  useEffect(() => {
    getPreviousWritings();
  }, []);

  // Clear previous errors when typing
  useEffect(() => {
    props.clearErrors();
  }, [selectedAnswer]);

  // Analyse text
  useEffect(() => {
    if (didSubmit) {
      props.analyseWritingText({ text: selectedAnswer, uid });
    }
  }, [didSubmit]);

  // Trigger display analysis
  useEffect(() => {
    if (analysis) {
      displayAnalysis();
      getScoreForListeningSummarizeCore(selectedAnswer, analysis, transcript);
    }
  }, [analysis]);

  // Save score
  useEffect(() => {
    if (score) {
      saveScore();
    }
  }, [score]);

  // Save score on submit
  function saveScore() {
    const finalScore = `${score.Final}/10`;
    props.setPracticeScore({
      uid,
      score: finalScore,
      successful: score.Final > 5,
      isAuthenticated: isAuthenticated(user),
    });

    // Save user writing
    props.saveUserWriting({
      uid,
      selectedAnswer,
      score: finalScore,
      scoreAnalysis: score,
      analysis: {
        Tags: analysis.Tags.filter((tag) => tag.report === "grammar"),
      },
    });
  }

  // Get spelling/grammatical errors
  function displayAnalysis() {
    // Get list of mistakes
    const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
    // Reverse order
    stableSort(errors, getSorting("desc", "endPos")).map((error) => {
      return highlightErrors(error);
    });

    // Build Html even if no errors
    if (isEmpty(errors)) {
      const inputText = document.getElementById("inputText");
      setHtmlJsx(inputText.innerHTML);
    }
  }

  // Highlight mistakes in text answer
  function highlightErrors(error) {
    const { hint, endPos, startPos, category, subcategory, suggestions } =
      error;

    const inputText = document.getElementById("inputText");
    var innerHTML = inputText.innerHTML;
    // Inject highlightword component in dummy input html
    innerHTML =
      innerHTML.substring(0, startPos) +
      `<highlightword word="` +
      subcategory +
      `"` +
      `hint="` +
      hint +
      `"` +
      `category="` +
      category +
      `"` +
      `suggestions="` +
      [suggestions] +
      `"></highlightword>` +
      innerHTML.substring(endPos + 1);
    inputText.innerHTML = innerHTML;

    setHtmlJsx(innerHTML);
  }

  // Get spelling/grammatical errors (previous writings)
  function getPreviousWritingAnalysis({ analysis, answer }) {
    // Get list of mistakes
    const errors = analysis.Tags.filter((tag) => tag.report === "grammar");

    const inputText = document.getElementById("inputPreviousText");
    inputText.innerHTML = answer;

    // Build Html even if no errors
    if (isEmpty(errors)) return `<p>${answer}</p>`;

    return stableSort(errors, getSorting("desc", "endPos")).map((error) => {
      return highlightPreviousWritingErrors(error);
    })[errors.length - 1];
  }

  // Highlight mistakes in text answer (previous writings)
  function highlightPreviousWritingErrors(error) {
    const { hint, endPos, startPos, category, subcategory, suggestions } =
      error;

    const inputText = document.getElementById("inputPreviousText");
    var innerHTML = inputText.innerHTML;
    // Inject highlightword component in dummy input html
    innerHTML =
      innerHTML.substring(0, startPos) +
      `<highlightword word="` +
      subcategory +
      `"` +
      `hint="` +
      hint +
      `"` +
      `category="` +
      category +
      `"` +
      `suggestions="` +
      [suggestions] +
      `"></highlightword>` +
      innerHTML.substring(endPos + 1);
    inputText.innerHTML = innerHTML;

    return innerHTML;
  }

  // Get previous writings
  async function getPreviousWritings() {
    const res = await props.getUserWritings({ uid });
    setPreviousWritings(res?.userWriting);
  }

  return (
    <Grid container direction="column" className={classes.screen}>
      <TaskBox uid={uid} />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <AudioPlayer track={track} />
      </div>
      {/* Textfield input */}
      <TextFieldGroup
        rows={8}
        multiline={true}
        disabled={didSubmit || isWritingDisabled}
        value={selectedAnswer}
        error={errors.text}
        placeholder="Type your answer here"
        customClass={classes.target}
        customContainer={classes.targetContainer}
        onChange={(e) => setSelectedAnswer(e.target.value)}
      />

      {isWritingDisabled ? (
        <Typography className={classes.limitedAi}>
          {/* Limited Ai */}
          {isPaidStudent({ user, settings: props.settings })
            ? "Your Ai writing analysis has been limited. Please contact the team."
            : "You have reached your free Ai writing credit limit. Check our packages for more."}
        </Typography>
      ) : (
        <Typography className={classes.wordCount}>
          {/* Word count */}
          Total Word Count:{" "}
          {selectedAnswer ? selectedAnswer?.trim()?.split(/\s+/).length : 0}
        </Typography>
      )}

      {/* Score */}
      {didSubmit && score && (
        <AlertSkillAnalysisWriting
          score={score}
          htmlJsx={htmlJsx}
          uid={uid}
          sampleAnswer={sampleAnswer}
        />
      )}

      {/* Previous writings */}
      {!isEmpty(previousWritings) && (
        <div>
          <Typography className={classes.previousSubmission}>
            Previous submissions:
          </Typography>

          <div style={{ display: "flex" }}>
            {previousWritings.map((previousWriting) => {
              if (!previousWriting) return null;

              return (
                <AlertSkillAnalysisPreviousWriting
                  uid={uid}
                  sampleAnswer={sampleAnswer}
                  score={previousWriting?.scoreAnalysis}
                  htmlJsx={getPreviousWritingAnalysis({
                    analysis: previousWriting?.analysis,
                    answer: previousWriting?.selectedAnswer,
                  })}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* Dummy div for inner html building */}
      <div id="inputText" style={{ display: "none" }}>
        {selectedAnswer}
      </div>
      {/* Dummy div for inner html building (previous writings) */}
      <div id="inputPreviousText" style={{ display: "none" }}></div>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flexGrow: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    flexDirection: "column",
    alignContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  targetContainer: {
    width: "100%",
  },
  target: {
    margin: 0,
  },
  disabled: {
    cursor: "default",
    "&:hover ": {
      opacity: 1,
      background: "white",
    },
  },
  wordCount: {
    width: "100%",
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  limitedAi: {
    color: "red",
    width: "100%",
    margin: "1rem 0",
  },
  previousSubmission: {
    width: "100%",
    marginTop: "1rem",
    fontSize: "1.3rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  settings: state.settings,
  analysis: state.analysis,
});

export default connect(mapStateToProps, {
  setAnalysis,
  clearErrors,
  getUserWritings,
  saveUserWriting,
  setPracticeScore,
  analyseWritingText,
})(ExerciseListeningSpokenTextCore);
