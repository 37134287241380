import isEmpty from "./isEmpty";
import {
  ENABLING_CONTENT,
  ENABLING_FLUENCY,
  ENABLING_PRONUNCIATION,
} from "./types";

// Clean string from punctuation and put to lower case
const cleanString = (str) => {
  return str
    .replace(/['!"“”#$%&\\'()\*+,\.\/:;<=>?@\[\\\]\^_`{|}~']/g, "")
    .toLowerCase();
};

// Clean up between brackets
const cleanBrackets = (result) => {
  var newResults = result
    ?.replace(/ *\([^)]*\) */g, "") // Remove anything in parentheses ex: (sigh)
    ?.replace(/(\[.*?\])/g, "") // Remove anything in brackets ex: [BLANK_AUDIO]
    ?.replace(/^[^\p{L}\p{N}]+|[^\p{L}\p{N}]+$/gu, "") // Clean before and after the string
    ?.replace("’", "'") // Match apostrophes
    ?.toLowerCase()
    ?.trim();

  return newResults;
};

// Take a string and return Camel String
const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return "";
    return index === 0 ? match.toUpperCase() : match.toLowerCase();
  });
};

// Return capitalized string and a full dot at the end
function makeItASentence(string) {
  return !isEmpty(string)
    ? string.charAt(0).toUpperCase() + string.slice(1) + "."
    : "";
}

// Function that count occurrences of a substring in a string;
const occurrences = (string, subString, allowOverlapping) => {
  string += "";
  subString += "";
  if (subString.length <= 0) return string.length + 1;

  var n = 0,
    pos = 0,
    step = allowOverlapping ? 1 : subString.length;

  while (true) {
    pos = string.indexOf(subString, pos);
    if (pos >= 0) {
      ++n;
      pos += step;
    } else break;
  }
  return n;
};

// Speaking - Compare 2 arrays of text and return indexes of matching words
const compareAndHighlight = (topic, speech) => {
  // Clean topic from punctuation and put to lower case
  const topicWords = topic.split(" ");
  var cleanedTopicWords = topicWords.map((word) => cleanString(word));

  // Clean speech from punctuation and put to lower case
  const speechWords = speech.split(" ").map((word) => cleanString(word));

  const highlightedIndexes = [];
  speechWords.map((word, index) => {
    // Check match between 2 arrays
    var i = cleanedTopicWords.indexOf(word.toLowerCase());
    if (i !== -1) {
      cleanedTopicWords[i] = "word_used_already";
      // Remove word found from temp topic array
      highlightedIndexes.push(i);
    }
    return null;
  });

  return highlightedIndexes;
};

// Return the topic as a string
const getTopicString = (question) => {
  if (question.uid.includes("RRP")) {
    // Reorder paragraphs only
    const { paragraphs, correctIndexes } = question;
    const correctParagraphs = correctIndexes.map(
      (val, i) => paragraphs[val - 1]
    );

    return correctParagraphs.join("\n\n");
  } else if (
    // For short question exercises
    question.uid.includes("SSQ")
  ) {
    return question.topic + " - " + question.correctAnswer;
  } else if (
    // For exercises with transcript
    question.uid.includes("RFG") ||
    question.uid.includes("RFB") ||
    question.section === "Speaking" ||
    question.section === "Listening"
  ) {
    return question.transcript || "";
  }

  // All exercises with topic
  return question.topic || "";
};

// Return rounded score between 0 and 90
const cleanScore = (number, max = 90) => {
  return Math.min(Math.max(Math.round(number), 0), max);
};

// Return rounded score between 0 and 5
const getFluencyScore = (number) => {
  if (number < 0.1 || number > 4.7) {
    return 0;
  } else if (number < 0.6 || number > 4.2) {
    return 1;
  } else if (number < 1.1 || number > 3.7) {
    return 2;
  } else if (number < 1.6 || number > 3.2) {
    return 3;
  } else if (number < 2.1 || number > 2.7) {
    return 4;
  } else if (number >= 2.1 || number <= 2.7) {
    return 5;
  } else {
    return 0;
  }
};

// Return rounded score between 0 and 5
const getPronunciationScore = (number, fluencyScore = 5) => {
  if (number >= 0.75 && fluencyScore >= 4) {
    return 5;
  } else if (number >= 0.7) {
    return 4;
  } else if (number >= 0.6) {
    return 3;
  } else if (number >= 0.5) {
    return 2;
  } else if (number >= 0.4) {
    return 1;
  } else {
    return 0;
  }
};

// Return rounded score between 0 and 5
const getEquivalentNewScore = (oldScore, type) => {
  if (type === ENABLING_CONTENT) {
    return Math.round((oldScore / 90) * 3);
  } else if (type === ENABLING_PRONUNCIATION) {
    var tempScore = Math.round((oldScore / 90) * 5);
    console.log("ENABLING_PRONUNCIATION ", tempScore);
    if (tempScore === 5) {
      return 0.9;
    } else if (tempScore === 4) {
      return 0.85;
    } else if (tempScore === 3) {
      return 0.8;
    } else if (tempScore === 2) {
      return 0.7;
    } else if (tempScore === 1) {
      return 0.6;
    } else if (oldScore === 0) {
      return 0;
    } else {
      return 0.5;
    }
  } else if (type === ENABLING_FLUENCY) {
    var tempScore = Math.round((oldScore / 90) * 5);
    console.log("ENABLING_FLUENCY ", tempScore);
    if (tempScore === 5) {
      return 2;
    } else if (tempScore === 4) {
      return 1.9;
    } else if (tempScore === 3) {
      return 1.7;
    } else if (tempScore === 2) {
      return 1.5;
    } else if (tempScore === 1) {
      return 1.3;
    } else {
      return 0;
    }
  }
};

var ones = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
var tens = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
var teens = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];

// Change numerical numbers into text
function convert(num) {
  if (num === 0) return "zero";
  else if (num < 10) {
    return ones[num];
  } else if (num >= 10 && num < 20) {
    return teens[num - 10];
  } else if (num < 100) {
    return tens[Math.floor(num / 10)] + " " + ones[num % 10];
  } else {
    return num;
  }
}

// Check if string has numbers
function hasNumber(string) {
  return /\d/.test(string);
}

// Change numerical numbers into text from array
const cleanNumbersFromArray = (array) => {
  if (isEmpty(array)) return array;
  return array.map((w) => {
    if (!hasNumber(w) || w.includes("%") || w.includes("$")) {
      // Don't change prices and percentage
      return w;
    } else {
      const arrayWithNumbers = w.split(/([_\W])/);
      return arrayWithNumbers.map((w) => convert(w))?.join("");
    }
  });
};

// Change numerical numbers into text from string
const cleanNumbersFromString = (string) => {
  if (isEmpty(string)) return string;
  if (!hasNumber(string) || string.includes("%") || string.includes("$")) {
    // Don't change prices and percentage
    return string;
  } else {
    return convert(string);
  }
};

export {
  camelize,
  cleanScore,
  cleanString,
  occurrences,
  cleanBrackets,
  getTopicString,
  makeItASentence,
  getFluencyScore,
  compareAndHighlight,
  getPronunciationScore,
  getEquivalentNewScore,
  cleanNumbersFromArray,
  cleanNumbersFromString,
};
