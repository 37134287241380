import ExerciseReadingSingle from "../components/screenExercise/ExerciseReadingSingle";
import ExerciseReadingReorder from "../components/screenExercise/ExerciseReadingReorder";
import ExerciseReadingMultiple from "../components/screenExercise/ExerciseReadingMultiple";
import ExerciseReadingFillBlanks from "../components/screenExercise/ExerciseReadingFillBlanks";
import ExerciseReadingWritingFillBlanks from "../components/screenExercise/ExerciseReadingWritingFillBlanks";

import ExerciseListeningMCQ from "../components/screenExercise/ExerciseListeningMCQ";
import ExerciseListeningMultiple from "../components/screenExercise/ExerciseListeningMultiple";
import ExerciseListeningFillBlanks from "../components/screenExercise/ExerciseListeningFillBlanks";
import ExerciseListeningSpokenText from "../components/screenExercise/ExerciseListeningSpokenText";
import ExerciseListeningSpokenTextCore from "../components/screenExercise/ExerciseListeningSpokenTextCore";
import ExerciseListeningWriteDictation from "../components/screenExercise/ExerciseListeningWriteDictation";
import ExerciseListeningIncorrectWords from "../components/screenExercise/ExerciseListeningIncorrectWords";

import ExerciseSpeakingRetell from "../components/screenExercise/ExerciseSpeakingRetell";
import ExerciseSpeakingDescribe from "../components/screenExercise/ExerciseSpeakingDescribe";
import ExerciseSpeakingReadAloud from "../components/screenExercise/ExerciseSpeakingReadAloud";
import ExerciseSpeakingSituation from "../components/screenExercise/ExerciseSpeakingSituation";
import ExerciseSpeakingShortQuestion from "../components/screenExercise/ExerciseSpeakingShortQuestion";
import ExerciseSpeakingRepeatSentences from "../components/screenExercise/ExerciseSpeakingRepeatSentences";

import ExerciseWritingEmail from "../components/screenExercise/ExerciseWritingEmail";
import ExerciseWritingEssay from "../components/screenExercise/ExerciseWritingEssay";
import ExerciseWritingSummarizeText from "../components/screenExercise/ExerciseWritingSummarizeText";
import ExerciseWritingSummarizeCore from "../components/screenExercise/ExerciseWritingSummarizeCore";

import {
  READING,
  WRITING,
  SPEAKING,
  LISTENING,
  EXAM_CORE,
  EXAM_UKVI,
  QUICK_MODE,
  ENABLING_GLR,
  ENABLING_DSC,
  ENABLING_VOC,
  ENABLING_FORM,
  EXAM_ACADEMIC,
  PERSONAL_INTRO,
  ENABLING_FLUENCY,
  ENABLING_CONTENT,
  ENABLING_GRAMMAR,
  CATEGORY_READING,
  CATEGORY_WRITING,
  CATEGORY_GENERAL,
  ENABLING_SPELLING,
  CATEGORY_SPEAKING,
  CATEGORY_LISTENING,
  SPEAKING_READ_ALOUD,
  ENABLING_EMAIL_CONV,
  ENABLING_APPROPRIACY,
  SPEAKING_SHORT_ANSWER,
  ENABLING_ORGANIZATION,
  ENABLING_PRONUNCIATION,
  SPEAKING_DESCRIBE_IMAGE,
  SPEAKING_RETELL_LECTURE,
  SPEAKING_REPEAT_SENTENCE,
  SPEAKING_RESPOND_SITUATION,
} from "./types";

const orange = "#FFB100";
const red = "#DA3025";
const green = "#65AB00";

// 1-3 FEW | 4-7 AVERAGE | 8+ MANY
const quantity = {
  FEW: { text: "FEW", color: green },
  AVERAGE: { text: "AVERAGE", color: orange },
  MANY: { text: "MANY", color: red },
};

// 1 EASY | 2-3 MEDIUM | 4-5 HARD
const difficulty = {
  EASY: { text: "EASY", color: green },
  MEDIUM: { text: "MEDIUM", color: orange },
  HARD: { text: "HARD", color: red },
};

// 10-20 MIN SLOW | 1-2 MIN MEDIUM | 10 SEC FAST
const timing = {
  SHORT: { text: "SHORT", color: green },
  MEDIUM: { text: "MEDIUM", color: orange },
  LONG: { text: "LONG", color: red },
};

// 1 LOW | 2-3 MEDIUM | 4-5 HIGH
const importance = {
  LOW: { text: "LOW", color: green },
  MEDIUM: { text: "MEDIUM", color: orange },
  HIGH: { text: "HIGH", color: red },
};

// Return settings for each exercise
const getExerciseSettings = (uid) => {
  if (uid.includes("ALL")) {
    return {
      abreviation: "A",
      name: "All",
      color: "#3665f3",
    };
  } else if (uid.includes("RSA")) {
    return {
      abreviation: "SA",
      uid: "RSA",
      name: "Single Answer",
      section: READING,
      color: "#A2CFFF",
      layout: ExerciseReadingSingle,
      targetTime: 120,
      showAnswerButton: false,
      hideSubmit: false,
      showForceAnswer: false,
      hideTimer: false,
      blogUrl: "",
      skillsAssessed: [READING],
      emptyAnswer: { selectedAnswer: "" },
      description:
        "You will be shown a text prompt for this assessment that will be up to 300 words. Possible response options will be given, with only one being correct. You will earn 1 point for correct answers and 0 points for incorrect answers.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "2 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2 - 3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "1 / 5",
          visual: importance.LOW.text,
          color: importance.LOW.color,
        },
      ],
      task: "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.",
      taskMock:
        "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.",
    };
  } else if (uid.includes("RMA")) {
    return {
      abreviation: "MA",
      uid: "RMA",
      name: "Multiple Answers",
      section: READING,
      color: "#73B7FF",
      layout: ExerciseReadingMultiple,
      targetTime: 180,
      showAnswerButton: false,
      hideSubmit: false,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [READING],
      emptyAnswer: { selectedAnswer: [] },
      description:
        "You will be shown a text prompt for this assessment that will be up to 300 words. You will need to answer the question by selecting the most suitable responses from the list of options. For each correct answer you will earn 1 point, however, you will lose -1 point for each incorrect answer. Don’t worry, you cannot score below 0 in this assessment.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "3 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2 - 3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "3 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "1 / 5",
          visual: importance.LOW.text,
          color: importance.LOW.color,
        },
      ],
      task: "Read the text and answer the question by selecting all the correct responses. You will need to select more than one response.",
      taskMock:
        "Read the text and answer the question by selecting all the correct responses. You will need to select more than one response.",
    };
  } else if (uid.includes("RRP")) {
    return {
      abreviation: "RP",
      uid: "RRP",
      name: "Re-order Paragraphs",
      section: READING,
      color: "#459FFF",
      layout: ExerciseReadingReorder,
      targetTime: 90,
      showAnswerButton: true,
      hideSubmit: false,
      noMargin: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [READING],
      emptyAnswer: { items: [] },
      description:
        "You will be provided with a text prompt of up to 50 words and will be shown some text boxes on the screen in a random order. Reposition the text in the correct order. You will earn 1 point for every pair of sentences that you place in the correct order and receive full marks if the whole paragraph is in the correct order.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "3 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "4 - 5",
          visual: quantity.AVERAGE.text,
          color: quantity.AVERAGE.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "2 / 5",
          visual: importance.MEDIUM.text,
          color: importance.MEDIUM.color,
        },
      ],
      task: "The text boxes have been placed in a random order. Restore the original order by dragging the text boxes.",
      taskMock:
        "The text boxes have been placed in a random order. Restore the original order by dragging the text boxes.",
    };
  } else if (uid.includes("RFG")) {
    return {
      abreviation: "FB",
      uid: "RFG",
      name: "Fill in Blanks",
      section: READING,
      color: "#1787FF",
      layout: ExerciseReadingFillBlanks,
      targetTime: 60,
      showAnswerButton: true,
      hideSubmit: false,
      noMargin: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [READING],
      emptyAnswer: { selectedAnswer: [] },
      description:
        "For this question, you will be provided with a text prompt of up to 80 words. There will be 3-5 missing words from the text. You will need to select a single correct answer from the 6-8 words given to fill the blanks. Not all words in the box will be used. You will earn 1 point for every correct answer.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "3 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "4 - 5",
          visual: quantity.AVERAGE.text,
          color: quantity.AVERAGE.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "3 / 5",
          visual: importance.MEDIUM.text,
          color: importance.MEDIUM.color,
        },
      ],
      task: "In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.",
      taskMock:
        "In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.",
    };
  } else if (uid.includes("RFB")) {
    return {
      abreviation: "FBW",
      uid: "RFB",
      name: "R/W Fill in Blanks",
      section: READING,
      color: "#0070E8",
      layout: ExerciseReadingWritingFillBlanks,
      targetTime: 180,
      showAnswerButton: true,
      hideSubmit: false,
      noMargin: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [READING, WRITING],
      emptyAnswer: { selectedAnswer: [] },
      description:
        "You will be shown a text prompt of up to 80 words with up to five blank spaces. select one of the five options in each drop-down list to fill in the blank spaces. You will earn 1 point for every blank space that is filled in correctly.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "4 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "5 - 6",
          visual: quantity.AVERAGE.text,
          color: quantity.AVERAGE.color,
        },
        {
          title: "Timing per Question",
          textValue: "3 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "5 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      task: "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.",
      taskMock:
        "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.",
    };
  } else if (uid.includes("LSA")) {
    return {
      abreviation: "SA",
      uid: "LSA",
      name: "Single Answer",
      section: LISTENING,
      color: "#C2E095",
      layout: ExerciseListeningMCQ,
      targetTime: 60,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [LISTENING],
      emptyAnswer: { selectedAnswer: "" },
      description:
        "To complete this task, you will be given information to read for context, you will then need to listen to a recording between 30-60 seconds long. Choose the option that best suits. Your response to this question type will be marked as correct or incorrect.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "1 / 5",
          visual: difficulty.EASY.text,
          color: difficulty.EASY.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "1 / 5",
          visual: importance.LOW.text,
          color: importance.LOW.color,
        },
      ],
      task: "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.",
      taskMock:
        "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.",
    };
  } else if (uid.includes("LMA")) {
    return {
      abreviation: "MA",
      uid: "LMA",
      name: "Multiple Answers",
      section: LISTENING,
      color: "#A9D46A",
      layout: ExerciseListeningMultiple,
      targetTime: 60,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [LISTENING],
      emptyAnswer: { selectedAnswer: [] },
      description:
        "First, you will be given information to help answer the question. You only have 7 seconds to read this. A recording will then play that is between 40-90 seconds long. Select all the response options that you think are correct. In this question type, you can receive 1 point for each correct option, but will lose -1 point for each incorrect option. You cannot score below 0.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "3 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "1 / 5",
          visual: importance.LOW.text,
          color: importance.LOW.color,
        },
      ],
      task: "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.",
      taskMock:
        "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.",
    };
  } else if (uid.includes("LWD")) {
    return {
      abreviation: "WD",
      uid: "LWD",
      name: "Write From Dictation",
      section: LISTENING,
      color: "#91C840",
      layout: ExerciseListeningWriteDictation,
      targetTime: 40,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [LISTENING, WRITING],
      emptyAnswer: { selectedAnswer: "" },
      description:
        "You will have 7 seconds to read the instructions before the recording starts. The recording is between 3-5 seconds long and once it starts, listen and type the sentence in the response box. You can receive 1 point for each correct word you write. Write the words in the right order and spell them correctly in order to receive 1 point. Points are not lost for incorrect words.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "3 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "3-4",
          visual: quantity.AVERAGE.text,
          color: quantity.AVERAGE.color,
        },
        {
          title: "Timing per Question",
          textValue: "1 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "5 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      task: "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can.",
      taskMock:
        "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.",
    };
  } else if (uid.includes("LHS")) {
    return {
      abreviation: "HS",
      uid: "LHS",
      name: "Highlight Correct Summary",
      section: LISTENING,
      color: "#79BC16",
      layout: ExerciseListeningMCQ,
      targetTime: 60,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [LISTENING, READING],
      emptyAnswer: { selectedAnswer: "" },
      description:
        "You will be given 10 seconds before the recording starts to read the options. A recording will be played that is between 30-90 seconds long. To complete the task, select only one correct response. If unsure, choose the one you think is correct as you cannot lose points in this question. Your response will either be marked as correct or incorrect.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "2 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "1 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "1 / 5",
          visual: importance.LOW.text,
          color: importance.LOW.color,
        },
      ],
      task: "You will hear a recording. Click on the paragraph that best relates to the recording.",
      taskMock:
        "You will hear a recording. Click on the paragraph that best relates to the recording.",
    };
  } else if (uid.includes("LIW")) {
    return {
      abreviation: "IW",
      uid: "LIW",
      name: "Highlight Incorrect Words",
      section: LISTENING,
      color: "#6EAB14",
      layout: ExerciseListeningIncorrectWords,
      targetTime: 60,
      showAnswerButton: true,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [LISTENING, READING],
      emptyAnswer: { selectedAnswer: [] },
      description:
        "In this question type, you will be shown information and have 10 seconds to read it before a recording starts. A recording between 15-50 seconds will play and you will need to select the words that are incorrect and do not match the recording. You can receive 1 point for each correct word and lose -1 point for each incorrect word. You cannot score below 0.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "2 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "1 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "3 / 5",
          visual: importance.MEDIUM.text,
          color: importance.MEDIUM.color,
        },
      ],
      task: "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.",
      taskMock:
        "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.",
    };
  } else if (uid.includes("LWB")) {
    return {
      abreviation: "FB",
      uid: "LWB",
      name: "Fill in Blanks",
      section: LISTENING,
      color: "#649A13",
      layout: ExerciseListeningFillBlanks,
      targetTime: 60,
      showAnswerButton: true,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [LISTENING, WRITING],
      emptyAnswer: { selectedAnswer: [] },
      description:
        "A recording between 30-60 seconds will play. As you listen, type each word in the blank spaces as you hear them so that you do not miss any. Do not leave any spaces blank, you can review them at the end. You receive 1 point for each correct word which is spelled correctly.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "3 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "1 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "3 / 5",
          visual: importance.MEDIUM.text,
          color: importance.MEDIUM.color,
        },
      ],
      task: "You will hear a recording. Type the missing words in each blank.",
      taskMock:
        "You will hear a recording. Type the missing words in each blank.",
    };
  } else if (uid.includes("LST")) {
    return {
      abreviation: "ST",
      uid: "LST",
      name: "Summarize Spoken Text",
      section: LISTENING,
      color: "#598911",
      layout: ExerciseListeningSpokenText,
      targetTime: 600,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [LISTENING, WRITING],
      emptyAnswer: {
        selectedAnswer: "",
        scoreAnalysis: {
          Form: 0,
          Final: 0,
          Content: 0,
          Grammar: 0,
          Spelling: 0,
          Vocabulary: 0,
          num_spell_mistake: 0,
          num_grammar_mistake: 0,
        },
        analysis: { Tags: [] },
      },
      description:
        "Listen to a 60-90 seconds long recording. As you listen, try to use the Erasable Noteboard Booklet to take notes of key points to help you understand the context. After the recording, you will have 10 minutes to write a summary of what you heard. To answer this question type, you must write a minimum of 50 words but no more than 70 words. You can receive a maximum of 2 points for content, 2 for form, 2 for grammar, 2 for vocabulary and 2 for spelling.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "4 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "1",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "10 MIN",
          visual: timing.LONG.text,
          color: timing.LONG.color,
        },
        {
          title: "Importance",
          textValue: "4 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      task: "After listening to a recording, write a 50-70 word summary. You can write more than one sentence. You have 10 minutes to finish this task.",
      taskMock:
        "You will hear a short lecture. Write a summary for a fellow student who was not present. You should write 50-70 words.\nYou have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.",
      skills: [
        ENABLING_CONTENT,
        ENABLING_FORM,
        ENABLING_GRAMMAR,
        ENABLING_VOC,
        ENABLING_SPELLING,
      ],
    };
  } else if (uid.includes("LCT")) {
    return {
      abreviation: "ST",
      uid: "LCT",
      name: "Summarize Spoken Text (Core)",
      section: LISTENING,
      color: "#598911",
      layout: ExerciseListeningSpokenTextCore,
      targetTime: 600,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [LISTENING, WRITING],
      emptyAnswer: {
        selectedAnswer: "",
        scoreAnalysis: {
          Form: 0,
          Final: 0,
          Content: 0,
          Grammar: 0,
          Spelling: 0,
          Vocabulary: 0,
          num_spell_mistake: 0,
          num_grammar_mistake: 0,
        },
        analysis: { Tags: [] },
      },
      description:
        "Listen to a 60-90 seconds long recording. As you listen, try to use the Erasable Noteboard Booklet to take notes of key points to help you understand the context. After the recording, you will have 10 minutes to write a summary of what you heard. To answer this question type, you must write a minimum of 20 words but no more than 30 words. You can receive a maximum of 2 points for content, 2 for form, 2 for grammar, 2 for vocabulary and 2 for spelling.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "4 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "1",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "10 MIN",
          visual: timing.LONG.text,
          color: timing.LONG.color,
        },
        {
          title: "Importance",
          textValue: "4 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      task: "After listening to a recording, write a 20-30 word summary. You can write more than one sentence. You have 10 minutes to finish this task.",
      taskMock:
        "You will hear a short lecture. Write a summary for a fellow student who was not present. You should write 20-30 words.\nYou have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.",
      skills: [
        ENABLING_CONTENT,
        ENABLING_FORM,
        ENABLING_GRAMMAR,
        ENABLING_VOC,
        ENABLING_SPELLING,
      ],
    };
  } else if (uid.includes("LMW")) {
    return {
      abreviation: "MW",
      uid: "LMW",
      name: "Select Missing Word",
      section: LISTENING,
      color: "#4E780F",
      layout: ExerciseListeningMCQ,
      targetTime: 60,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      waitAudioDidEnd: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [LISTENING],
      emptyAnswer: { selectedAnswer: "" },
      description:
        "A recording between 20-70 seconds long will be played and you will need to predict the last word or words of the text to complete the task. You will be marked on this question type as either correct or incorrect.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "2 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "1 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "1 / 5",
          visual: importance.LOW.text,
          color: importance.LOW.color,
        },
      ],
      task: "You will hear a recording. At the end of the recording the last word or group of words has been replaced by a beep. Select the correct option to complete the recording.",
      taskMock:
        "You will hear a recording. At the end of the recording the last word or group of words has been replaced by a beep. Select the correct option to complete the recording.",
    };
  } else if (uid.includes("SRA")) {
    return {
      abreviation: "RA",
      uid: "SRA",
      name: SPEAKING_READ_ALOUD,
      section: SPEAKING,
      color: "#F1DA68",
      layout: ExerciseSpeakingReadAloud,
      targetTime: 80,
      showAnswerButton: false,
      hideSubmit: true,
      noMargin: true,
      waitPlayerDidEnd: true,
      showForceAnswer: false,
      hideTimer: true,
      importantExercise: true,
      skillsAssessed: [READING, SPEAKING],
      emptyAnswer: { speechText: [""] },
      description:
        "You will be shown a text prompt that will be up to 60 words. You will have 30-40 seconds to prepare by reading the passage. You will also have a set time to read aloud, which will vary by item, depending on the length of the text. You can receive a maximum of 3 points for the content spoken, maximum of 5 points for oral fluency and a maximum of 5 points for pronunciation.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "3 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "6-7",
          visual: quantity.AVERAGE.text,
          color: quantity.AVERAGE.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "5 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      waitingTime: localStorage[QUICK_MODE] ? 4 : 40,
      answeringTime: 40,
      task: "Look at the text below. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud. The microphone will stop after 3 seconds of silence!",
      taskMock:
        "Look at the text below. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.",
      skills: [ENABLING_CONTENT, ENABLING_FLUENCY, ENABLING_PRONUNCIATION],
    };
  } else if (uid.includes("SRS")) {
    return {
      abreviation: "RS",
      uid: "SRS",
      name: SPEAKING_REPEAT_SENTENCE,
      section: SPEAKING,
      color: "#EDCF3D",
      layout: ExerciseSpeakingRepeatSentences,
      targetTime: 30,
      showAnswerButton: false,
      hideSubmit: true,
      noMargin: true,
      waitPlayerDidEnd: true,
      showForceAnswer: true,
      hideTimer: true,
      importantExercise: true,
      skillsAssessed: [LISTENING, SPEAKING],
      emptyAnswer: { speechText: [""] },
      description:
        "You will listen to a recording between 3-9 seconds and are given 15 seconds to answer. You need to speak straight away and say all the words that the speaker uses, in the same order. You can receive a maximum score of 3 for content, maximum 5 for oral fluency and a maximum of 5 points for pronunciation.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "2 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "8-10",
          visual: quantity.MANY.text,
          color: quantity.MANY.color,
        },
        {
          title: "Timing per Question",
          textValue: "1 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "5 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      waitingTime: 1,
      answeringTime: 15,
      task: "You will hear a sentence. Please repeat the sentence exactly as you hear it. The microphone will stop after 3 seconds of silence!",
      taskMock:
        "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.",
      skills: [ENABLING_CONTENT, ENABLING_FLUENCY, ENABLING_PRONUNCIATION],
    };
  } else if (uid.includes("SSQ")) {
    return {
      abreviation: "SQ",
      uid: "SSQ",
      name: SPEAKING_SHORT_ANSWER,
      section: SPEAKING,
      color: "#EEC600",
      layout: ExerciseSpeakingShortQuestion,
      targetTime: 10,
      showAnswerButton: false,
      hideSubmit: true,
      noMargin: true,
      waitPlayerDidEnd: true,
      showForceAnswer: true,
      hideTimer: true,
      skillsAssessed: [SPEAKING, LISTENING],
      emptyAnswer: { speechText: [""] },
      description:
        "You will be given a prompt of 3-9 seconds and will have 10 seconds to answer the question. You will be required to answer a short question with a clear, simple, single word or a few words. This will not test general knowledge, it will simply test your vocabulary. You will receive 1 point per correct word. No points are given for no response or incorrect response.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "2 / 5",
          visual: difficulty.MEDIUM.text,
          color: difficulty.MEDIUM.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "10-12",
          visual: quantity.MANY.text,
          color: quantity.MANY.color,
        },
        {
          title: "Timing per Question",
          textValue: "10 SEC",
          visual: timing.SHORT.text,
          color: timing.SHORT.color,
        },
        {
          title: "Importance",
          textValue: "2 / 5",
          visual: importance.MEDIUM.text,
          color: importance.MEDIUM.color,
        },
      ],
      waitingTime: 1,
      answeringTime: 10,
      task: "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.",
      taskMock:
        "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.",
      skills: [ENABLING_CONTENT],
    };
  } else if (uid.includes("SDI")) {
    return {
      abreviation: "DI",
      uid: "SDI",
      name: SPEAKING_DESCRIBE_IMAGE,
      section: SPEAKING,
      color: "#D4B412",
      layout: ExerciseSpeakingDescribe,
      targetTime: 70,
      showAnswerButton: false,
      hideSubmit: true,
      noMargin: true,
      waitPlayerDidEnd: true,
      showForceAnswer: false,
      hideTimer: true,
      importantExercise: true,
      skillsAssessed: [SPEAKING],
      emptyAnswer: { speechText: [""] },
      description:
        "You will be shown an image and have 25 seconds to study the image and prepare your response. For this task, use the Erasable Noteboard Booklet to take short notes. After a short tone is heard, start speaking straight away. You will have 40 seconds to answer and must finish speaking before the progress bar reaches the end. In this question type, you can receive a maximum of 5 points for content, maximum of 5 points for oral fluency and maximum of 5 points for pronunciation.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "4 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "6-8",
          visual: quantity.AVERAGE.text,
          color: quantity.AVERAGE.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "4 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      waitingTime: localStorage[QUICK_MODE] ? 4 : 25,
      answeringTime: 40,
      task: "Look at the image below. In 25 seconds, please speak into the microphone and describe in detail what the image is showing. You will have 40 seconds to give your response. The microphone will stop after 3 seconds of silence!",
      taskMock:
        "Look at the image below. In 25 seconds, please speak into the microphone and describe in detail what the image is showing. You will have 40 seconds to give your response.",
      skills: [ENABLING_CONTENT, ENABLING_FLUENCY, ENABLING_PRONUNCIATION],
    };
  } else if (uid.includes("SRL")) {
    return {
      abreviation: "RL",
      uid: "SRL",
      name: SPEAKING_RETELL_LECTURE,
      section: SPEAKING,
      color: "#BFA210",
      layout: ExerciseSpeakingRetell,
      targetTime: 130,
      showAnswerButton: false,
      hideSubmit: true,
      noMargin: true,
      waitPlayerDidEnd: true,
      showForceAnswer: false,
      hideTimer: true,
      importantExercise: true,
      skillsAssessed: [SPEAKING, LISTENING],
      emptyAnswer: { speechText: [""] },
      description:
        "You will be given a prompt of up to 90 seconds and will have 40 seconds to answer. Audio will be played straight away and you may also see an image related to the audio. While you are listening use the Erasable Noteboard Booklet to take short notes. Once the audio finishes you have 10 seconds to prepare. To complete the task, in detail, you will need to cover the situation, characters, aspects, actions and developments given in the recording. You can receive a maximum score of 5 points on content, a maximum of 5 points on your oral fluency and a maximum of 5 points for pronunciation.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "5 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "4 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      waitingTime: localStorage[QUICK_MODE] ? 4 : 10,
      answeringTime: 40,
      task: "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response. The microphone will stop after 3 seconds of silence!",
      taskMock:
        "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.",
      skills: [ENABLING_CONTENT, ENABLING_FLUENCY, ENABLING_PRONUNCIATION],
    };
  } else if (uid.includes("SIT")) {
    return {
      abreviation: "SI",
      uid: "SIT",
      name: SPEAKING_RESPOND_SITUATION,
      section: SPEAKING,
      color: "#BFA210",
      layout: ExerciseSpeakingSituation,
      targetTime: 60,
      showAnswerButton: false,
      hideSubmit: true,
      noMargin: true,
      waitPlayerDidEnd: true,
      showForceAnswer: false,
      hideTimer: true,
      importantExercise: true,
      skillsAssessed: [SPEAKING, LISTENING],
      emptyAnswer: { speechText: [""] },
      description:
        "For this question type you need to listen to and read a prompt describing an everyday situation. You then need to provide a spoken response describing what you would say in the situation. You will have 20 seconds to think about your response, and then 40 seconds to speak.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "5 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "2 MIN",
          visual: timing.MEDIUM.text,
          color: timing.MEDIUM.color,
        },
        {
          title: "Importance",
          textValue: "4 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      waitingTime: localStorage[QUICK_MODE] ? 4 : 20,
      answeringTime: 40,
      task: "Listen to and read a description of a situation. Then answer a question about it with an extended response. You will have 20 seconds to think about your answer. You will have 40 seconds to give your response. The microphone will stop after 3 seconds of silence!",
      taskMock:
        "Listen to and read a description of a situation. Then answer a question about it with an extended response. You will have 20 seconds to think about your answer. You will have 40 seconds to give your response.",
      skills: [ENABLING_APPROPRIACY, ENABLING_FLUENCY, ENABLING_PRONUNCIATION],
    };
  } else if (uid.includes("WWM")) {
    return {
      abreviation: "WM",
      uid: "WWM",
      name: "Email",
      section: WRITING,
      color: "#D85C5C",
      layout: ExerciseWritingEmail,
      targetTime: 540,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [WRITING],
      emptyAnswer: {
        selectedAnswer: "",
        scoreAnalysis: {
          Form: 0,
          Final: 0,
          Content: 0,
          Grammar: 0,
          Spelling: 0,
          Vocabulary: 0,
          Organization: 0,
          num_spell_mistake: 0,
          num_grammar_mistake: 0,
          "Email conventions": 0,
        },
        analysis: { Tags: [] },
      },
      description:
        "In this question type, you will be provided with a situation prompt and have 9 minutes to answer by writing a 50-120 word email in response to this prompt. This task is scored on seven different traits. You can receive a maximum of three points for content and a maximum of two points for email conventions, form, organization, vocabulary, grammar, and spelling. There is a total of 15 points available for this question type.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "5 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "9 MIN",
          visual: timing.LONG.text,
          color: timing.LONG.color,
        },
        {
          title: "Importance",
          textValue: "3 / 5",
          visual: importance.MEDIUM.text,
          color: importance.MEDIUM.color,
        },
      ],
      task: "You will have 9 minutes to plan, write and revise an email of approximately 100 words in response to a prompt. Your response is judged on your ability to write an appropriate email on a given topic. Make sure to write a minimum of 50 words, but no more than 120 words.",
      taskMock:
        "Read a description of a situation. Then write an e-mail about the situation. You will have 9 minutes. You should aim to write at least 100 words. Write using complete sentences.",
      skills: [
        ENABLING_CONTENT,
        ENABLING_EMAIL_CONV,
        ENABLING_FORM,
        ENABLING_ORGANIZATION,
        ENABLING_VOC,
        ENABLING_GRAMMAR,
        ENABLING_SPELLING,
      ],
    };
  } else if (uid.includes("WWE")) {
    return {
      abreviation: "WE",
      uid: "WWE",
      name: "Essay",
      section: WRITING,
      color: "#D85C5C",
      layout: ExerciseWritingEssay,
      targetTime: 1200,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      showForceAnswer: false,
      hideTimer: false,
      skillsAssessed: [WRITING],
      emptyAnswer: {
        selectedAnswer: "",
        scoreAnalysis: {
          Form: 0,
          Final: 0,
          Content: 0,
          Grammar: 0,
          Spelling: 0,
          Vocabulary: 0,
          num_spell_mistake: 0,
          num_grammar_mistake: 0,
          "General linguistic range": 0,
          "Development, structure and coherence": 0,
        },
        analysis: { Tags: [] },
      },
      description:
        "In this question type, you will be provided with 2-3 sentences of text prompt and have 20 minutes to answer, by writing a 200-300 word argumentative essay on a given topic. You can receive a maximum of 3 points for the content, maximum of 2 points for the formal requirements and maximum 2 points for development, structure and coherence. You can also receive a maximum score of 2 points for grammar, maximum 2 points for general linguistic range, maximum 2 points for vocabulary range and a maximum of 2 points for no spelling errors. There is a total of 15 points that you can pick up in this question type.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "5 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "1-2",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "20 MIN",
          visual: timing.LONG.text,
          color: timing.LONG.color,
        },
        {
          title: "Importance",
          textValue: "3 / 5",
          visual: importance.MEDIUM.text,
          color: importance.MEDIUM.color,
        },
      ],
      task: "You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200–300 words.",
      taskMock:
        "You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200–300 words.",
      skills: [
        ENABLING_CONTENT,
        ENABLING_FORM,
        ENABLING_DSC,
        ENABLING_GRAMMAR,
        ENABLING_GLR,
        ENABLING_VOC,
        ENABLING_SPELLING,
      ],
    };
  } else if (uid.includes("WWS")) {
    return {
      abreviation: "SWT",
      uid: "WWS",
      name: "Summarize Written Text",
      section: WRITING,
      color: "#CA2020",
      layout: ExerciseWritingSummarizeText,
      targetTime: 600,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [READING, WRITING],
      emptyAnswer: {
        selectedAnswer: "",
        scoreAnalysis: {
          Form: 0,
          Final: 0,
          Content: 0,
          Grammar: 0,
          Vocabulary: 0,
          num_grammar_mistake: 0,
        },
        analysis: { Tags: [] },
      },
      description:
        "You will read a text of up to 300 words and have 10 minutes to write your summary. You will do 2-3 of these questions depending on the combination of tasks in the given test. You must write just one sentence. Try using the Erasable Noteboard Booklet to take notes of the main points. Make sure to include the main points of the text in a full, single sentence of no more than 75 words. You can receive a maximum score of 2 points for content, maximum 1 point for the formal requirements, a maximum of 2 points for grammar and a maximum of 2 points for vocabulary.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "4 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "10 MIN",
          visual: timing.LONG.text,
          color: timing.LONG.color,
        },
        {
          title: "Importance",
          textValue: "4 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      task: "Read the passage below and summarize it using one sentence. Type your response in the box at the bottom. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage. You should write 75 words maximum.",
      taskMock:
        "Read the passage below and summarize it using one sentence. Type your response in the box at the bottom. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.",
      skills: [ENABLING_FORM, ENABLING_CONTENT, ENABLING_GRAMMAR, ENABLING_VOC],
    };
  } else if (uid.includes("WSC")) {
    return {
      abreviation: "SWT",
      uid: "WSC",
      name: "Summarize Written Text (Core)",
      section: WRITING,
      color: "#CA2020",
      layout: ExerciseWritingSummarizeCore,
      targetTime: 600,
      showAnswerButton: false,
      hideSubmit: false,
      noMargin: true,
      showForceAnswer: false,
      hideTimer: false,
      importantExercise: true,
      skillsAssessed: [READING, WRITING],
      emptyAnswer: {
        selectedAnswer: "",
        scoreAnalysis: {
          Form: 0,
          Final: 0,
          Content: 0,
          Grammar: 0,
          Vocabulary: 0,
          num_grammar_mistake: 0,
        },
        analysis: { Tags: [] },
      },
      description:
        "You will read a text of up to 200 words and have 10 minutes to write your summary. You will do 2-3 of these questions depending on the combination of tasks in the given test. Read the text. Then write a short 25–50 word summary. Try using the Erasable Noteboard Booklet to take notes of the main points. Make sure to include the main points of the reading passage in 25 to 50 words. You can receive a maximum score of 2 points for content, maximum 2 points for the formal requirements, a maximum of 2 points for grammar and a maximum of 2 points for vocabulary.",
      stats: [
        {
          title: "Exercise Difficulty",
          textValue: "4 / 5",
          visual: difficulty.HARD.text,
          color: difficulty.HARD.color,
        },
        {
          title: "Quantity per Exam",
          textValue: "2-3",
          visual: quantity.FEW.text,
          color: quantity.FEW.color,
        },
        {
          title: "Timing per Question",
          textValue: "10 MIN",
          visual: timing.LONG.text,
          color: timing.LONG.color,
        },
        {
          title: "Importance",
          textValue: "4 / 5",
          visual: importance.HIGH.text,
          color: importance.HIGH.color,
        },
      ],
      task: "Read the text. Then write a short 25–50 word summary. Type your response in the box at the bottom. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.",
      taskMock:
        "Read the text. Then write a short 25–50 word summary. Type your response in the box at the bottom. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.",
      skills: [ENABLING_FORM, ENABLING_CONTENT, ENABLING_GRAMMAR, ENABLING_VOC],
    };
  } else if (uid.includes(PERSONAL_INTRO)) {
    return {
      waitingTime: localStorage[QUICK_MODE] ? 4 : 25,
      answeringTime: 30,
      taskMock:
        "Read the prompt below. In 25 seconds, you must reply in your own words, as naturally and clearly as possible. You have 30 seconds to record your response. Your response will be sent together with your score report to the institutions selected by you.",
    };
  } else {
    // Prevent potential crash if no uid
    return {
      abreviation: "",
      uid: "",
      name: "",
      section: "",
      color: "",
      layout: ExerciseReadingSingle,
      targetTime: 60,
      showAnswerButton: true,
      hideSubmit: false,
      showForceAnswer: false,
      hideTimer: false,
      task: "",
      taskMock: "",
      waitingTime: 0,
      answeringTime: 0,
    };
  }
};

// Return list of type of exams
const getExamTypeList = [EXAM_ACADEMIC, EXAM_CORE, EXAM_UKVI];

// Return list of exercise per section
const getExerciseForSection = (section, examType = EXAM_ACADEMIC) => {
  if (examType === EXAM_CORE) {
    if (section === READING) {
      return ["ALL", "RSA", "RMA", "RRP", "RFG", "RFB"];
    } else if (section === LISTENING) {
      return ["ALL", "LSA", "LMA", "LWD", "LIW", "LWB", "LCT", "LMW"];
    } else if (section === SPEAKING) {
      return ["ALL", "SRA", "SRS", "SIT", "SSQ", "SDI"];
    } else if (section === WRITING) {
      return ["ALL", "WWM", "WSC"];
    }
  } else {
    if (section === READING) {
      return ["ALL", "RSA", "RMA", "RRP", "RFG", "RFB"];
    } else if (section === LISTENING) {
      return ["ALL", "LSA", "LMA", "LWD", "LHS", "LIW", "LWB", "LST", "LMW"];
    } else if (section === SPEAKING) {
      return ["ALL", "SRA", "SRS", "SSQ", "SDI", "SRL"];
    } else if (section === WRITING) {
      return ["ALL", "WWE", "WWS"];
    }
  }
};

// Return categories main color
const getCategoryColor = (category) => {
  if (category === CATEGORY_READING) {
    return "#007BFF";
  } else if (category === CATEGORY_LISTENING) {
    return "#79BC16";
  } else if (category === CATEGORY_SPEAKING) {
    return "#EEC600";
  } else if (category === CATEGORY_WRITING) {
    return "#CA2020";
  } else if (category === CATEGORY_GENERAL) {
    return "purple";
  } else {
    return "#223a4c";
  }
};

export {
  getExamTypeList,
  getCategoryColor,
  getExerciseSettings,
  getExerciseForSection,
};
