import clsx from "clsx";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Navbar from "./components/layout/Navbar";
import SideDrawer from "./components/layout/SideDrawer";
import OnBoardingSmall from "./components/common/OnBoardingSmall";
import OnBoardingLarge from "./components/common/OnBoardingLarge";

import TawkToManager from "./components/common/TawkToManager";
import PrivateRoute from "./components/common/PrivateRoute";
import PromoReceiver from "./components/common/PromoReceiver";
import SpinnerAnalyse from "./components/common/SpinnerAnalyse";
import AlertUpdateApp from "./components/alerts/AlertUpdateApp";
import SpinnerLoading from "./components/common/SpinnerLoading";
import PublicOnlyRoute from "./components/common/PublicOnlyRoute";
import MetadataManager from "./components/common/MetadataManager";
import MobileAppBanner from "./components/common/MobileAppBanner";
import AlertTestimonial from "./components/alerts/AlertTestimonial";
import PrivateAdminRoute from "./components/common/PrivateAdminRoute";
import NavigationManager from "./components/common/NavigationManager";
import OverviewStudentsSnackbar from "./components/common/OverviewStudentsSnackbar";

import {
  ROUTE_HELP,
  ROUTE_USER,
  ROUTE_BLOG,
  ROUTE_PERK,
  ROUTE_HOME,
  ROUTE_ADMIN,
  ROUTE_PROMO,
  ROUTE_ABOUT,
  ROUTE_LOGIN,
  ROUTE_VERIFY,
  ROUTE_REWARD,
  ROUTE_SIGNUP,
  ROUTE_SURVEY,
  ROUTE_LANDING,
  ROUTE_PRICING,
  ROUTE_CHECKOUT,
  ROUTE_PASSWORD,
  ROUTE_PRACTICE,
  ROUTE_EXERCISE,
  ROUTE_RESOURCES,
  ROUTE_NOT_FOUND,
  ROUTE_TEMPLATES,
  // ROUTE_SCORECARD,
  ROUTE_DASHBOARD,
  ROUTE_MOCK_TEST,
  ROUTE_THANK_YOU,
  ROUTE_VOCABULARY,
  ROUTE_STATISTICS,
  ROUTE_CONTACT_US,
  ROUTE_MOBILE_APP,
  ROUTE_REGULATIONS,
  ROUTE_SET_PASSWORD,
  ROUTE_TESTIMONIALS,
  ROUTE_DESKTOP_ONLY,
  ROUTE_EXERCISE_MOCK,
  ROUTE_MOBILE_APP_IOS,
  ROUTE_AFFILIATE_CHECK,
  ROUTE_THANK_YOU_MOBILE,
  ROUTE_MOCK_TEST_RESULT,
  ROUTE_MOBILE_APP_ANDROID,
  ROUTE_MOCK_TEST_SCORECARD,
  STEPS_SHOULD_SET_PASSWORD,
  ROUTE_EXERCISE_MOCK_DESKTOP,
} from "./helpers/types";
import {
  isAdmin,
  isPaidStudent,
  isAuthenticated,
  isInPracticeMode,
} from "./helpers/userCheck";
import { getDateNow } from "./actions/settingActions";
import getNotifSnackbar from "./helpers/getNotifSnackbar";
import { clearSnackbar } from "./actions/notificationActions";
import { fetchAllAdminSettings } from "./actions/adminActions";
import { resetCredits, syncMobile } from "./actions/userActions";
import { fetchAllPackages, fetchAllPromotions } from "./actions/pricingActions";

const ScreenFaq = lazy(() => import("./screens/ScreenFaq"));
const ScreenHome = lazy(() => import("./screens/ScreenHome"));
const ScreenUser = lazy(() => import("./screens/ScreenUser"));
const ScreenBlog = lazy(() => import("./screens/ScreenBlog"));
const ScreenPerk = lazy(() => import("./screens/ScreenPerk"));
const ScreenAdmin = lazy(() => import("./screens/ScreenAdmin"));
const ScreenAbout = lazy(() => import("./screens/ScreenAbout"));
const ScreenLogin = lazy(() => import("./screens/ScreenLogin"));
const ScreenSurvey = lazy(() => import("./screens/ScreenSurvey"));
const ScreenReward = lazy(() => import("./screens/ScreenReward"));
const ScreenPricing = lazy(() => import("./screens/ScreenPricing"));
const ScreenCheckout = lazy(() => import("./screens/ScreenCheckout"));
const ScreenPractice = lazy(() => import("./screens/ScreenPractice"));
const ScreenExercise = lazy(() => import("./screens/ScreenExercise"));
const ScreenMockTest = lazy(() => import("./screens/ScreenMockTest"));
const ScreenThankYou = lazy(() => import("./screens/ScreenThankYou"));
const ScreenMobileApp = lazy(() => import("./screens/ScreenMobileApp"));
const ScreenResources = lazy(() => import("./screens/ScreenResources"));
const ScreenTemplates = lazy(() => import("./screens/ScreenTemplates"));
// const ScreenScorecard = lazy(() => import('./screens/ScreenScorecard'));
const ScreenContactUs = lazy(() => import("./screens/ScreenContactUs"));
const ScreenDashboard = lazy(() => import("./screens/ScreenDashboard"));
const ScreenVocabulary = lazy(() => import("./screens/ScreenVocabulary"));
const ScreenStatistics = lazy(() => import("./screens/ScreenStatistics"));
const ScreenDesktopOnly = lazy(() => import("./screens/ScreenDesktopOnly"));
const ScreenRegulations = lazy(() => import("./screens/ScreenRegulations"));
const ScreenMobileAppIos = lazy(() => import("./screens/ScreenMobileAppIos"));
const ScreenTestimonials = lazy(() => import("./screens/ScreenTestimonials"));
const ScreenThankYouMobile = lazy(() =>
  import("./screens/ScreenThankYouMobile")
);
const ScreenDynamicLanding = lazy(() =>
  import("./screens/ScreenDynamicLanding")
);

const ScreenMockTestScorecardPublic = lazy(() =>
  import("./screens/ScreenMockTestScorecardPublic")
);
const ScreenMockTestResult = lazy(() =>
  import("./screens/ScreenMockTestResult")
);
// const ScreenPteReadingTest = lazy(() =>
//   import("./screens/ScreenPteReadingTest")
// );
const ScreenMobileAppAndroid = lazy(() =>
  import("./screens/ScreenMobileAppAndroid")
);
const ScreenMockTestExercise = lazy(() =>
  import("./screens/ScreenMockTestExercise")
);
const ScreenMockTestDesktop = lazy(() =>
  import("./screens/ScreenMockTestDesktop")
);

// Components
const VerifyEmailChecker = lazy(() =>
  import("./components/common/VerifyEmailChecker")
);
const SetPasswordChecker = lazy(() =>
  import("./components/common/SetPasswordChecker")
);
const ResetPasswordChecker = lazy(() =>
  import("./components/common/ResetPasswordChecker")
);
const FloatingTestimonialBox = lazy(() =>
  import("./components/common/TestimonialBox/FloatingTestimonialBox")
);
const NotFound = lazy(() => import("./components/common/NotFound"));
const BlogPage = lazy(() => import("./components/screenBlog/BlogPage"));

// Main Parent with router
function Parent(props) {
  const classes = useStyles();
  const { pathname } = props.location;
  const { isMockTest, isReviewMode } = props.practice;
  const { user, admins, settings, practice, packages, notification } = props;
  const largeDevice = useMediaQuery("(min-width:900px)");
  const isSurvey = pathname === ROUTE_SURVEY;
  const isCheckout = pathname === ROUTE_CHECKOUT;

  const [didSync, setDidSync] = useState(false);
  const [mounted, setMounted] = useState(false);

  // Trigger rendering when mounted
  useEffect(() => {
    setMounted(true);
    if (!packages && mounted) {
      props.getDateNow();
      props.fetchAllPackages();
      props.fetchAllPromotions();
    }
  }, [mounted]);

  // Trigger fetch for admin only when mounted
  useEffect(() => {
    if (isAdmin(user) && !admins) {
      props.fetchAllAdminSettings();
    }
  }, [user, admins]);

  // Check mobile app subscription
  useEffect(() => {
    const syncMobile = async () => {
      await props.syncMobile();
    };
    if (
      !didSync &&
      user?.adaptyId &&
      settings?.dateNow > 0 &&
      isAuthenticated(user) &&
      !isPaidStudent({ user, settings })
    ) {
      syncMobile();
      setDidSync(true);
    } else {
      if (isAuthenticated(user) && !isPaidStudent({ user, settings })) {
        props.resetCredits();
      }
    }
  }, [user, settings]);

  // Display snackbar notif (bottom left)
  useEffect(() => {
    if (notification) {
      const notifData = getNotifSnackbar[notification];
      if (!notifData) return;
      const { variant, message } = notifData;
      props.enqueueSnackbar(message, { variant, ariaAttributes: message });
      props.clearSnackbar();
    }
  }, [notification]);

  return (
    <>
      <MetadataManager />
      <div className={classes.app}>
        <Navbar />
        {isAuthenticated(user) &&
          !isSurvey &&
          !isMockTest &&
          !isCheckout &&
          largeDevice &&
          !isReviewMode &&
          !isInPracticeMode(practice) &&
          !user?.stepsDone?.includes(STEPS_SHOULD_SET_PASSWORD) && (
            <SideDrawer />
          )}
        <main
          className={clsx(classes.main, {
            [classes.mainPaddingLeft]:
              !isSurvey &&
              largeDevice &&
              !isMockTest &&
              !isCheckout &&
              isAuthenticated(user) &&
              !isInPracticeMode(practice) &&
              !user?.stepsDone?.includes(STEPS_SHOULD_SET_PASSWORD),
            [classes.mainPaddingMockTest]: isMockTest || isReviewMode,
            [classes.mainPaddingCheckout]: isCheckout || isSurvey,
            // SideDrawer padding (hide in practice)
          })}
        >
          <Suspense fallback={<SpinnerLoading />}>
            <Switch>
              <Redirect from="/home" to="/" />
              <Redirect
                from="/blog/difference-between-ielts-and-pte"
                to="/blog/ielts-to-pte"
              />
              <Redirect
                from="/blog/how-to-pass-the-pte-test"
                to="/blog/pte-tips"
              />
              <Redirect from="/blog/pte-tips" to="/blog/pte-exam-tips" />
              <Redirect
                from="/blog/how-to-score-high-in-pte-reading-section"
                to="/blog/pte-reading-tips"
              />
              <Redirect
                from="/blog/the-top-4-tips-to-improve-your-pte-essay-score"
                to="/blog/how-to-write-an-excellent-pte-essay"
              />
              <Redirect
                from="/blog/pte-how-to-make-sure-you-wont-be-off-topic-in-your-write-essay"
                to="/blog/pte-written-discourse"
              />
              <Redirect
                from="/blog/pte-reading"
                to="/blog/pte-reading-format"
              />
              <Redirect
                from="/blog/pte-listening"
                to="/blog/pte-listening-format"
              />
              <Redirect
                from="/blog/pte-speaking"
                to="/blog/pte-speaking-format"
              />
              <Redirect
                from="/blog/pte-writing"
                to="/blog/pte-writing-format"
              />
              <Redirect
                from="/blog/the-ultimate-guide-to-improve-pte-vocabulary"
                to="/blog/pte-vocabulary"
              />
              <Redirect
                from="/blog/how-to-use-linking-words-in-your-pte-essay-part-1"
                to="/blog/pte-linking-words"
              />
              <Redirect
                from="/blog/how-to-use-linking-words-in-your-pte-essay-part-2"
                to="/blog/pte-linking-words"
              />
              <Redirect from="/blog/what-is-pte" to="/blog/pte-guide" />
              <PublicOnlyRoute exact path={ROUTE_HOME} component={ScreenHome} />
              <PublicOnlyRoute
                exact
                path={ROUTE_AFFILIATE_CHECK}
                component={ScreenHome}
              />
              <PublicOnlyRoute
                exact
                path={ROUTE_SIGNUP}
                component={ScreenLogin}
              />
              <PublicOnlyRoute
                exact
                path={ROUTE_LOGIN}
                component={ScreenLogin}
              />
              <Route exact path={ROUTE_PRICING} component={ScreenPricing} />
              <Route exact path={ROUTE_CHECKOUT} component={ScreenCheckout} />
              <PrivateRoute
                exact
                path={ROUTE_DASHBOARD}
                component={ScreenDashboard}
              />
              <Route exact path={ROUTE_PRACTICE} component={ScreenPractice} />
              {/* <Route
									exact
									path={'/pte-reading-test'}
									component={ScreenPteReadingTest}
								/> */}
              <Route exact path={ROUTE_EXERCISE} component={ScreenExercise} />
              <Route exact path={ROUTE_MOCK_TEST} component={ScreenMockTest} />
              <Route
                exact
                path={ROUTE_EXERCISE_MOCK}
                component={ScreenMockTestExercise}
              />
              <Route
                exact
                path={ROUTE_EXERCISE_MOCK_DESKTOP}
                component={ScreenMockTestDesktop}
              />
              <Route
                exact
                path={ROUTE_MOCK_TEST_RESULT}
                component={ScreenMockTestResult}
              />
              <Route
                exact
                path={`${ROUTE_MOCK_TEST_SCORECARD}/:sessionId`}
                component={ScreenMockTestScorecardPublic}
              />
              <Route
                exact
                path={`${ROUTE_LANDING}/:slug`}
                component={ScreenDynamicLanding}
              />
              <PrivateRoute
                exact
                path={ROUTE_TEMPLATES}
                component={ScreenTemplates}
              />
              <PrivateRoute
                exact
                path={ROUTE_RESOURCES}
                component={ScreenResources}
              />
              <PrivateRoute
                exact
                path={ROUTE_VOCABULARY}
                component={ScreenVocabulary}
              />
              <PrivateRoute
                exact
                path={ROUTE_STATISTICS}
                component={ScreenStatistics}
              />
              <PrivateRoute exact path={ROUTE_USER} component={ScreenUser} />
              <PrivateAdminRoute
                exact
                path={ROUTE_ADMIN}
                component={ScreenAdmin}
              />
              <Route exact path={ROUTE_BLOG} component={ScreenBlog} />
              <Route exact path={ROUTE_PERK} component={ScreenPerk} />
              <Route exact path="/blog/:slug" component={BlogPage} />
              <Route exact path={ROUTE_HELP} component={ScreenFaq} />
              <Route exact path={ROUTE_SURVEY} component={ScreenSurvey} />
              <Route
                exact
                path={ROUTE_MOBILE_APP}
                component={ScreenMobileApp}
              />

              <Route
                exact
                path={ROUTE_MOBILE_APP_IOS}
                component={ScreenMobileAppIos}
              />
              <Route
                exact
                path={ROUTE_MOBILE_APP_ANDROID}
                component={ScreenMobileAppAndroid}
              />
              <Route exact path={ROUTE_ABOUT} component={ScreenAbout} />
              <Route
                exact
                path={ROUTE_TESTIMONIALS}
                component={ScreenTestimonials}
              />
              <Route
                exact
                path={`${ROUTE_REGULATIONS}/:slug`}
                component={ScreenRegulations}
              />
              <Route exact path={ROUTE_THANK_YOU} component={ScreenThankYou} />
              <Route
                exact
                path={ROUTE_THANK_YOU_MOBILE}
                component={ScreenThankYouMobile}
              />
              {/* <Route
                  exact
                  path={ROUTE_SCORECARD}
                  component={ScreenScorecard}
                /> */}
              <Route
                exact
                path={ROUTE_CONTACT_US}
                component={ScreenContactUs}
              />
              <PrivateRoute
                exact
                path={ROUTE_REWARD}
                component={ScreenReward}
              />
              <Route exact path={ROUTE_VERIFY} component={VerifyEmailChecker} />
              <Route exact path={ROUTE_PROMO} component={PromoReceiver} />
              <Route
                exact
                path={ROUTE_PASSWORD}
                component={ResetPasswordChecker}
              />
              <PrivateRoute
                exact
                path={ROUTE_SET_PASSWORD}
                component={SetPasswordChecker}
              />
              <Route
                exact
                path={ROUTE_DESKTOP_ONLY}
                component={ScreenDesktopOnly}
              />
              <Route exact path={ROUTE_NOT_FOUND} component={NotFound} />
              <Route component={NotFound} />
            </Switch>
            {mounted && <FloatingTestimonialBox />}
          </Suspense>
        </main>
      </div>

      {mounted && (
        <>
          <TawkToManager />
          <SpinnerLoading />
          <SpinnerAnalyse />
          <AlertUpdateApp />
          {largeDevice && <OnBoardingSmall />}
          {largeDevice && <OnBoardingLarge />}
          <AlertTestimonial />
          <NavigationManager />
          <OverviewStudentsSnackbar />
          {!largeDevice && <MobileAppBanner />}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  app: {
    background: theme.customColors.background,
  },
  main: {
    flex: 1,
    margin: "auto",
    display: "flex",
    maxWidth: "1600px",
    paddingTop: theme.customVars.navbarHeight,
    background: theme.customColors.background,
    minHeight: `calc(100vh - ${theme.customVars.navbarHeight})`,
  },
  mainPaddingLeft: {
    paddingLeft: "65px",
  },
  mainPaddingMockTest: {
    paddingTop: 0,
    maxWidth: "unset",
  },
  mainPaddingCheckout: {
    paddingTop: 0,
    maxWidth: "unset",
    minHeight: "100vh",
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  admins: state.admins,
  practice: state.practice,
  packages: state.packages,
  settings: state.settings,
  notification: state.notification,
});

export default connect(mapStateToProps, {
  syncMobile,
  getDateNow,
  resetCredits,
  clearSnackbar,
  fetchAllPackages,
  fetchAllPromotions,
  fetchAllAdminSettings,
})(withRouter(withSnackbar(Parent)));
